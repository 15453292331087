import React, { Component } from "react";
import { Grid, Row, Col, Table } from "react-bootstrap";
import Card from "../Card/Card";
import Button from "../CustomButton/CustomButton.jsx";
import ReactToPrint from "react-to-print";
import {
  // HOST,
  PINCODE_LIST,
  UPDATE_ORDER,
  ORDERS_BY_LOCATION,
} from "../../Network/ApiPaths";
import Uri from "../../Network/Uri";
// import getResponse from "../../Network/GetDataResponse";
import getResponseClient from "../../Network/GetClientDataResponse";
import postClientDataResponse from "../../Network/PostClientDataResponse";
// import ReactToPrint from "react-to-print";

//import postDataResponse from "../../Network/PostDataResponse";

// const client = window.localStorage.getItem("clientAccess");
let storeFresh;
let storeUserId;
// let storeIdValue;
let clientHost;

let storeAddress;
let storeDet;
let storeName;

class Orders1 extends Component {
  constructor(props) {
    super();
    // const userCheck = window.localStorage.getItem("access");
    // storeDet = JSON.parse(userCheck);
    // if (storeDet !== null) {
    //   storeIdValue = storeDet.storeId;
    //   storeAddress = storeDet.address;
    // } else {
    //   storeAddress = "";
    //   storeIdValue = "";
    // }
    const userCheck = window.localStorage.getItem("access");
    storeDet = JSON.parse(userCheck);
    if (storeDet !== null) {
      storeAddress = storeDet.address;
    } else {
      storeAddress = "";
    }
    const client = window.localStorage.getItem("clientAccess");
    storeFresh = JSON.parse(client);
    if (storeFresh !== null) {
      storeName = JSON.parse(window.localStorage.getItem("clientAccess"))
        .storeName;
      clientHost = JSON.parse(window.localStorage.getItem("clientAccess")).host;
    }
    this.state = {
      totalOrders: "",
      totalSales: "",
      netSales: "",
      average: "",
      orders: [],
      // type: "",
      onOrder: false,
      oid: "",
      items: [],
      userDetails: [],
      ordersList: [],
      assignee: "",
      cancelReason: "",
      track: false,
      cReason: "",
      type: "4",
      date: "",
      salesDate: "",
      pinCode: "",
    };
  }
  onFieldChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  onPinSelect = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
    let p = e.target.value;
    if (p.length > 4) {
      this.getDefaultOrders(p);
    }
  };
  getDefaultOrders = (p) => {
    var date = new Date().toISOString().slice(0, 10);
    // this.state.salesDate = date;
    // this.getSalesByDate();
    var apiPath = new Uri(
      // HOST +
      clientHost + ORDERS_BY_LOCATION
    )
      // .addQueryParam("store_id", storeFresh.storeId)
      // .addQueryParam("offset", "0")
      // .addQueryParam("limit", "150")
      .addQueryParam("startDate", date)
      .addQueryParam("date", `${date}`)
      //  23:59:59
      .addQueryParam("order_status", "4")
      .addQueryParam("lat", storeFresh.latitute)
      .addQueryParam("log", storeFresh.longitude)
      .addQueryParam("km", `10`)
      .addQueryParam("pincode", p);
    getResponseClient(
      apiPath,
      storeFresh.key,
      (onSuccessResponse) => {
        this.setState({
          orders: onSuccessResponse.result,
          items: [],
          // type: onSuccessResponse.result[0].order_status,
        });
      },
      (onError) => {
        alert(onError.message);
      }
    );
  };
  orderType = (e) => {
    this.setState({
      type: e.target.value,
      orders: [],
      items: [],
      oid: "",
    });

    // if (e.target.value === "1") {
    //   this.getOrders(e.target.value);
    // } else if (e.target.value === "2") {
    //   this.getOrders(e.target.value);
    // } else if (e.target.value === "3") {
    //   this.getOrders(e.target.value);
    // } else if (e.target.value === "4") {
    //   this.getOrders(e.target.value);
    // } else if (e.target.value === "5") {
    //   this.getOrders(e.target.value);
    // } else if (e.target.value === "6") {
    //   this.getOrders(e.target.value);
    // } else {
    this.getOrders(e.target.value);
    // }
  };
  getOrders = (i) => {
    var apiPath = new Uri(clientHost + ORDERS_BY_LOCATION)
      // .addQueryParam("store_id", storeFresh.storeId)
      // .addQueryParam("offset", "0")
      // .addQueryParam("limit", "150")
      .addQueryParam("order_status", i)
      .addQueryParam("lat", storeFresh.latitute)
      .addQueryParam("log", storeFresh.longitude)
      .addQueryParam("km", `10`)
      .addQueryParam("pincode", this.state.pinCode);
    getResponseClient(
      apiPath,
      storeFresh.key,
      (onSuccessResponse) => {
        this.setState({
          orders: onSuccessResponse.result,
          items: [],
          // type: onSuccessResponse.result[0].order_status,
        });
      },
      (onError) => {
        alert(onError.message);
      }
    );
  };
  getItems = (p) => {
    var apiPath = new Uri(clientHost + `Order/getOrderItems?order_id=${p}`);
    getResponseClient(
      apiPath,
      storeFresh.key,
      (onSuccessResponse) => {
        let res = onSuccessResponse.result[0];
        this.setState({
          items: onSuccessResponse.result,
          userDetails: onSuccessResponse.userinfo,
          onOrder: true,
          oid: res.item.order_id,
        });
      },
      (onError) => {
        alert(onError.message);
      }
    );
  };
  getItemwiseList = () => {
    let todaysOrdersDate = new Date().toISOString().slice(0, 10);
    // console.log( "0",e.target.value,"1",todaysOrdersDate,"2",new Date(e.target.value))
    let salesDate = this.state.salesDate;
    if (salesDate !== "") {
      todaysOrdersDate = salesDate;
    }
    var apiPath = new Uri(
      clientHost + `Reports/getProductwiseSale?store_id=${storeFresh.storeId}`
    )
      .addQueryParam("startDate", todaysOrdersDate)
      .addQueryParam("endDate", todaysOrdersDate);
    getResponseClient(
      apiPath,
      storeFresh.key,
      (onSuccessResponse) => {
        // this.getItemwiseList();
        this.setState({
          ordersList: onSuccessResponse.response,
        });
      },
      (onError) => {
        alert(onError.message);
      }
    );
  };
  selectAssignee = (poid) => {
    const { assignee } = this.state;
    const { oid } = this.state;
    if (oid === "") {
      alert("order id error");
    } else if (assignee === "") {
      alert("assignee error");
    } else if (assignee === "dunzo") {
      var apiPath = new Uri(UPDATE_ORDER);
      const requestBody = {
        order_status: "1",
        aggregator: "dunzo",
        id: poid,
        userId: storeUserId,
      };

      postClientDataResponse(
        clientHost + apiPath,
        storeFresh.key,
        requestBody,
        (onSuccessResponse) => {
          this.getOrders();
          this.setState({
            items: [],
            userDetails: [],
            assignee: "",
            oid: "",
          });
        },
        (onError) => {
          alert(onError.message);
        }
      );
    } else {
      var apiPath2 = new Uri(UPDATE_ORDER);
      const requestBody = {
        order_status: "1",
        aggregator: "",
        id: poid,
        userId: storeUserId,
      };
      postClientDataResponse(
        clientHost + apiPath2,
        storeFresh.key,
        requestBody,
        (onSuccessResponse) => {
          this.getOrders();
          this.setState({
            items: [],
            userDetails: [],
            assignee: "",
            oid: "",
          });
        },
        (onError) => {
          alert(onError.message);
        }
      );
    }
  };
  ProceedOrder = (poid) => {
    let oType = this.state.type;
    let proceedStatus;
    // if (oType === "7") proceedStatus = "1";
    // else
    const { oid } = this.state;
    if (oid === "") {
      alert("order id error");
    } else {
      if (oType === "1") proceedStatus = "6";
      else if (oType === "6") proceedStatus = "2";
      else if (oType === "2") proceedStatus = "3";
      else if (oType === "3") proceedStatus = "4";
      else {
      }
      // const { oid } = this.state;
      var apiPath = new Uri(UPDATE_ORDER);
      const requestBody = {
        order_status: proceedStatus,
        aggregator: "",
        id: poid,
        userId: storeUserId,
      };
      postClientDataResponse(
        clientHost + apiPath,
        storeFresh.key,
        requestBody,
        (onSuccessResponse) => {
          this.getOrders();
          this.setState({
            items: [],
            userDetails: [],
            oid: "",
          });
        },
        (onError) => {
          alert(onError.message);
        }
      );
    }
  };
  // initiateCancel = (cid) => {
  //   if (cid === "") {
  //     alert("order id error");
  //   } else if (this.state.cReason === "") {
  //     alert("select Reason");
  //   }
  //   //  else {
  //   //   this.CancelOrder(cid);
  //   // }
  // };
  CancelOrder = (coid) => {
    const { oid } = this.state;
    if (oid === "") {
      alert("order id error");
    } else if (this.state.cancelReason === "") {
      alert("Select Reason");
    } else {
      var apiPath = new Uri(UPDATE_ORDER);
      const requestBody = {
        order_status: "9",
        cancel_reason: this.state.cancelReason,
        id: oid,
        // id: coid,
        userId: storeUserId,
      };
      postClientDataResponse(
        clientHost + apiPath,
        storeFresh.key,
        requestBody,
        (onSuccessResponse) => {
          this.getOrders();
          this.setState({
            items: [],
            userDetails: [],
            type: "",
            oid: "",
          });
        },
        (onError) => {
          alert(onError.message);
        }
      );
    }
  };
  CancelConfirm = (coid) => {
    const { oid } = this.state;
    if (oid === "") {
      alert("order id error");
    }
    // else if (this.state.cancelReason === "") {alert("Select Reason")}
    else {
      var apiPath = new Uri(UPDATE_ORDER);
      const requestBody = {
        order_status: "5",
        cancel_reason: this.state.cancelReason,
        id: coid,
        userId: storeUserId,
      };
      postClientDataResponse(
        clientHost + apiPath,
        storeFresh.key,
        requestBody,
        (onSuccessResponse) => {
          this.getOrders();
          this.setState({
            items: [],
            userDetails: [],
            type: "",
            oid: "",
          });
        },
        (onError) => {
          alert(onError.message);
        }
      );
    }
  };

  track = () => {
    console.log("track now");
    this.setState({
      onOrder: false,
      track: true,
    });
  };
  goBackToOrder = () => {
    this.setState({
      onOrder: true,
      track: false,
    });
  };
  getSalesByDate = (e) => {
    let date = new Date(e.target.value).toISOString().slice(0, 10);
    // console.log("0",e.target.value,"1",date,"2",new Date(e.target.value))
    let salesDate = new Date(e.target.value).toISOString().slice(0, 10);
    this.setState({ salesDate });
    var apiPath = new Uri(
      // HOST +
      clientHost + ORDERS_BY_LOCATION
    )
      // .addQueryParam("store_id", storeFresh.storeId)
      // .addQueryParam("offset", "0")
      // .addQueryParam("limit", "150")
      .addQueryParam("startDate", salesDate)
      .addQueryParam("order_status", this.state.type)
      .addQueryParam("date", `${date} 23:59:59`)
      .addQueryParam("lat", storeFresh.latitute)
      .addQueryParam("log", storeFresh.longitude)
      .addQueryParam("km", `10`)
      .addQueryParam("pincode", this.state.pinCode);
    getResponseClient(
      apiPath,
      storeFresh.key,
      (onSuccessResponse) => {
        this.setState(
          {
            orders: onSuccessResponse.result,
            items: [],

            // type: onSuccessResponse.result[0].order_status,
          }
          //  this.getItemwiseList()
        );
      },
      (onError) => {
        alert(onError.message);
      }
    );
  };
  getDate = () => {
    var date = new Date().toISOString().slice(0, 10);
    this.setState({ date });
  };
  // download = (filename, text) => {
  //   var element = document.createElement("a");
  //   element.setAttribute(
  //     "href",
  //     "data:text/plain;charset=utf-8," + encodeURIComponent(text)
  //   );
  //   element.setAttribute("download", filename);

  //   element.style.display = "none";
  //   document.body.appendChild(element);

  //   element.click();

  //   document.body.removeChild(element);
  // };
  // getOrderDetails = () => {
  //   var apiPath = new Uri(
  //     clientHost + `Report/getTodaySalesCount`
  //   ).addQueryParam("storeId", storeFresh.storeId);
  //   getResponseClient(
  //     apiPath,
  //     storeFresh.key,
  //     (onSuccessResponse) => {
  //       onSuccessResponse.response.map((p) =>
  //         this.setState({
  //           totalOrders: p.OrdersCount,
  //           totalSales: p.TotalAmount,
  //           netSales: p.NetSale,
  //           average: p.Average,
  //         })
  //       );
  //     },
  //     (onError) => {
  //       // alert(onError);
  //     }
  //   );
  // };
  render() {
    const userCheck = window.localStorage.getItem("access");
    let storeDet = JSON.parse(userCheck);
    let storeUserRole;
    if (storeDet !== null) {
      storeUserRole = storeDet.role;
    } else {
      storeUserRole = "";
    }
    const styles = { green: { color: "#008000" }, red: { color: "#FF0000" } };
    const orders = this.state.orders;
    const items = this.state.items;
    // const { userDetails } = this.state;
    // const storeDet = window.localStorage.getItem("access");
    // console.log(JSON.parse(storeDet));
    return (
      <div>
        <div className="content">
          <Grid fluid>
            <Row>
              <Col md={9}>
                <Card
                  ctAllIcons
                  content={
                    <Row style={{ padding: "2%" }}>
                      <br />
                      <select
                        onChange={this.orderType}
                        style={{
                          width: "100px",
                          height: "25px",
                          fontSize: "15px",
                        }}
                        defaultValue="4"
                      >
                        <option value="">---</option>
                        {storeUserRole === "SHOP" ? null : (
                          <>
                            <option value="7">Pending</option>
                            <option value="1">Accepted</option>
                            <option value="6">Assigned</option>
                            <option value="2">Picked_up</option>
                            <option value="3">Delivered</option>
                          </>
                        )}

                        <option value="4">Completed</option>
                        <option value="9">Cancel Requests</option>
                        <option value="5">Cancelled</option>
                      </select>
                      <div
                        className="col-sm-4"
                        style={{
                          position: "absolute",
                          right: "50%",
                        }}
                      >
                        <input
                          list="pincodes"
                          name="pinCode"
                          onChange={this.onPinSelect}
                          placeholder="Enter Pin Code"
                          className="form-control form-control-sm"
                          style={{ background: "#E0E0E0" }}
                        />
                        <datalist id="pincodes">
                          {this.state.pincodes
                            ? this.state.pincodes.map((p) => (
                                <option value={p.name}></option>
                              ))
                            : null}
                        </datalist>
                      </div>
                      <br />
                      <div style={{ position: "absolute", right: "25%" }}>
                        <h3 style={{ color: "gray" }}>
                          Orders Count:{" "}
                          {orders !== null
                            ? orders.filter(
                                (p) =>
                                  p.dated.substr(0, p.dated.indexOf(" ")) ===
                                  this.state.salesDate
                              ).length
                            : null}
                        </h3>
                      </div>
                      {/* <div style={{ display: "none" }}>
                        <div ref={(el) => (this.componentRef = el)}> */}
                      {/* <table>
                            <thead>
                              {storeAddress ? (
                                <>
                                  <tr>
                                    <th style={{ textAlign: "center" }}>
                                      {storeAddress.split(",")[1]},{" "}
                                      {storeAddress.split(",")[2]},
                                    </th>
                                  </tr>
                                  <tr>
                                    <th style={{ textAlign: "center" }}>
                                      {storeAddress.split(",")[3]},{" "}
                                      {storeAddress.split(",")[4]},
                                    </th>
                                  </tr>
                                  <tr>
                                    <th style={{ textAlign: "center" }}>
                                      {storeAddress.split(",")[5]}
                                    </th>
                                  </tr>
                                </>
                              ) : null}
                              <br />
                            </thead>
                            <tbody>
                              <tr>
                                <td>Date:{this.state.salesDate}</td>
                              </tr>
                              <br />
                              <tr>
                                <th>Items</th>
                                <th>Qty</th>
                                <th>Size</th>
                                <th>Amt</th>
                              </tr>
                              <br />
                              {this.state.ordersList
                                ? this.state.ordersList.map((p, index) => {
                                    return (
                                      <>
                                        <tr key={index}>
                                          <td>
                                            {p.productname.split(" ")[0]}&nbsp;
                                            {p.productname.split(" ")[1]}
                                          </td>
                                          <td>{p.quantity}&nbsp;</td>
                                          <td>{p.size}&nbsp;</td>
                                          <td>{p.totalamount}</td>
                                        </tr>
                                        {p.productname.split(" ")[2] ? (
                                          <tr>
                                            <td>
                                              {p.productname.split(" ")[2]}
                                              &nbsp;
                                              {p.productname.split(" ")[3]}
                                            </td>
                                          </tr>
                                        ) : null}
                                      </>
                                    );
                                  })
                                : null}
                            </tbody>
                          </table> */}
                      {/* </div>
                      </div> */}
                      {/* <ReactToPrint
                        // onAfterPrint={this.refreshPage}
                        trigger={() => (
                          <button
                            style={{ position: "absolute", right: "0" }}
                            type="button"
                            className="btn btn-primary"
                            // href="profile"
                          >
                            download
                          </button>
                        )}
                        content={() => this.componentRef}
                      /> */}
                      <a
                        style={{ position: "absolute", right: "0%" }}
                        href={
                          clientHost.split("/backend/api")[0] +
                          // `/reports/Report/dailySale`
                          `/reports/Report/dailySalesReport?storeId=${
                            storeFresh.storeId
                          }&startDate=${
                            this.state.salesDate
                              ? this.state.salesDate
                              : this.state.date
                          }&endDate=${
                            this.state.salesDate
                              ? this.state.salesDate
                              : this.state.date
                          }`
                        }
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        [ Download ]
                      </a>
                    </Row>
                  }
                />
              </Col>
              <div style={{ display: "none" }}>
                <div ref={(el) => (this.componentRef2 = el)}>
                  <table>
                    <thead>
                      <tr>
                        <th style={{ textAlign: "center" }}>{storeName}</th>
                      </tr>
                      {storeAddress ? (
                        <>
                          <tr>
                            <th style={{ textAlign: "center" }}>
                              {storeAddress.split(",")[0]},{" "}
                              {storeAddress.split(",")[1]},
                            </th>
                          </tr>
                          <tr>
                            <th style={{ textAlign: "center" }}>
                              {storeAddress.split(",")[2]},{" "}
                              {storeAddress.split(",")[3]},
                            </th>
                          </tr>
                          <tr>
                            <th style={{ textAlign: "center" }}>
                              {storeAddress.split(",")[4]},{" "}
                              {storeAddress.split(",")[5]}
                            </th>
                          </tr>
                        </>
                      ) : null}
                      <tr>
                        <td>--------------------------</td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          Bill No:
                          {items !== []
                            ? items[0]
                              ? orders.filter(
                                  (s) => s.id === items[0].item.order_id
                                )[0].bill_no
                              : null
                            : null}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Order No:
                          {items !== []
                            ? items[0]
                              ? items[0].item.order_id
                              : null
                            : null}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Date:
                          {items !== [] ? (
                            items[0] ? (
                              <>
                                {
                                  (orders
                                    .filter(
                                      (s) => s.id === items[0].item.order_id
                                    )[0]
                                    .dated.split(" ")[0],
                                  orders
                                    .filter(
                                      (s) => s.id === items[0].item.order_id
                                    )[0]
                                    .dated.split(" ")[1])
                                }
                              </>
                            ) : null
                          ) : null}
                        </td>
                      </tr>
                      <tr>
                        <td>--------------------------</td>
                      </tr>
                      <tr>
                        <th>
                          Menu&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Qty&nbsp;Rate&nbsp;Amt
                        </th>
                      </tr>
                      <tr>
                        <td>--------------------------</td>
                      </tr>

                      {items !== []
                        ? items.map((p, index) => {
                            let itemDet = p.item;
                            // console.log(itemDet);
                            return (
                              <>
                                <tr key={index}>
                                  {itemDet ? (
                                    <td>
                                      {itemDet.name.length > 11 ? (
                                        <>{itemDet.name.substring(0, 11)}...</>
                                      ) : null}{" "}
                                      {itemDet.name.length === 11 ? (
                                        <>{itemDet.name}&nbsp;&nbsp;&nbsp;</>
                                      ) : null}{" "}
                                      {itemDet.name.length === 10 ? (
                                        <>
                                          {itemDet.name}&nbsp;&nbsp;&nbsp;&nbsp;
                                        </>
                                      ) : null}{" "}
                                      {itemDet.name.length === 9 ? (
                                        <>
                                          {itemDet.name}
                                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        </>
                                      ) : null}{" "}
                                      {itemDet.name.length === 8 ? (
                                        <>
                                          {itemDet.name}
                                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        </>
                                      ) : null}{" "}
                                      {itemDet.name.length === 7 ? (
                                        <>
                                          {itemDet.name}
                                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        </>
                                      ) : null}{" "}
                                      {itemDet.name.length === 6 ? (
                                        <>
                                          {itemDet.name}
                                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        </>
                                      ) : null}{" "}
                                      {itemDet.name.length < 5 ? (
                                        <>
                                          {itemDet.name}
                                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        </>
                                      ) : null}{" "}
                                      {itemDet.quantity}&nbsp;
                                      {(
                                        (Number(itemDet.price) * 100) /
                                        105
                                      ).toFixed(2)}
                                      &nbsp;&nbsp;
                                      {Math.round(
                                        (Number(itemDet.price) *
                                          Number(itemDet.quantity) *
                                          100) /
                                          105
                                      )}
                                    </td>
                                  ) : null}
                                </tr>
                                <tr>
                                  <td>
                                    {itemDet.size === "None" ||
                                    itemDet.size === "NONE"
                                      ? null
                                      : itemDet.size}{" "}
                                  </td>
                                </tr>
                                {/* {p.addons
                                ? p.addons !== [] || null
                                  ? p.addons.map((r, index) => (
                                      <tr key={index}>
                                        <td>
                                          &nbsp;-
                                          {r.name.length > 10 ? (
                                            <>{r.name.substring(0, 9)}...</>
                                          ) : (
                                            <>{r.name} </>
                                          )}
                                          {p.quantity}
                                        </td>
                                      </tr>
                                    ))
                                  : null
                                : null} */}
                              </>
                            );
                          })
                        : null}
                      <tr>
                        <td>&nbsp;</td>
                      </tr>
                      <tr>
                        <td>
                          SubTotal:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Rs.
                          {items !== []
                            ? items[0]
                              ? (
                                  (Number(
                                    orders.filter(
                                      (s) => s.id === items[0].item.order_id
                                    )[0].final_amount
                                  ) *
                                    100) /
                                  105
                                ).toFixed(2)
                              : null
                            : null}
                          {/* {totalPrice} */}
                        </td>
                      </tr>
                      <tr>
                        <td>--------------------------</td>
                      </tr>
                      <tr>
                        <td>
                          &nbsp;&nbsp;CGST 2.5%&nbsp;&nbsp;&nbsp;&nbsp;Rs.
                          {items !== []
                            ? items[0]
                              ? Math.round(
                                  0.025 *
                                    ((Number(
                                      orders.filter(
                                        (s) => s.id === items[0].item.order_id
                                      )[0].final_amount
                                    ) *
                                      100) /
                                      105) *
                                    100
                                ) / 100
                              : null
                            : null}
                          {/* {Math.round((2.5 / 100) * totalPrice * 100) / 100} */}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          &nbsp;&nbsp;SGST 2.5%&nbsp;&nbsp;&nbsp;&nbsp;Rs.
                          {items !== []
                            ? items[0]
                              ? Math.round(
                                  0.025 *
                                    ((Number(
                                      orders.filter(
                                        (s) => s.id === items[0].item.order_id
                                      )[0].final_amount
                                    ) *
                                      100) /
                                      105) *
                                    100
                                ) / 100
                              : null
                            : null}
                          {/* {Math.round((2.5 / 100) * totalPrice * 100) / 100} */}
                        </td>
                      </tr>
                      <tr>
                        <td>--------------------------</td>
                      </tr>
                      <tr>
                        <td>
                          Total Amount&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Rs.
                          {items !== []
                            ? items[0]
                              ? orders.filter(
                                  (s) => s.id === items[0].item.order_id
                                )[0].final_amount
                              : null
                            : null}
                          {/* {Math.round(this.state.cartPrice.cartTotal * 100) /
                            100} */}
                        </td>
                      </tr>
                      <tr style={{ textAlign: "center" }}>
                        <td>
                          <strong>
                            PAY: Rs.
                            {items !== []
                              ? items[0]
                                ? Math.round(
                                    orders.filter(
                                      (s) => s.id === items[0].item.order_id
                                    )[0].final_amount
                                  )
                                : null
                              : null}
                            {/* {Math.round(this.state.cartPrice.cartTotal)} */}
                          </strong>
                        </td>
                      </tr>

                      <tr>
                        <td>--------------------------</td>
                      </tr>

                      <tr style={{ textAlign: "center" }}>
                        {/* <td>GSTIN : {gst}</td> */}
                      </tr>

                      <tr style={{ textAlign: "center" }}>
                        <td>Thank you, visit again</td>
                      </tr>
                      <tr style={{ textAlign: "center" }}>
                        <td>Please check your belongings</td>
                      </tr>
                      <tr style={{ textAlign: "center" }}>
                        <td>before you leave</td>
                      </tr>
                      <tr style={{ textAlign: "center" }}>
                        <td>Powered by - TreoSoft IT</td>
                      </tr>
                      <tr>
                        <td>Printed on {new Date().toLocaleString()}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <Col md={3}>
                <Card
                  ctAllIcons
                  content={
                    <Row>
                      <input
                        type="date"
                        className="form-control"
                        placeholder="provide Start Date"
                        defaultValue={this.state.date}
                        name="getSalesByDate"
                        max={this.state.date}
                        onChange={this.getSalesByDate}
                        style={{ position: "absolute", top: "2%" }}
                        // value={
                        //   this.state.sDate === ""
                        //     ? this.state.date
                        //     : this.state.eDate === ""
                        //     ? this.state.sDate
                        //     : // this.state.sDate !== this.state.date ? null :
                        //     new Date(this.state.eDate) <=
                        //       new Date(this.state.sDate)
                        //     ? this.state.sDate
                        //     : this.state.eDate
                        // }
                      />
                    </Row>
                  }
                />
              </Col>
            </Row>
          </Grid>
        </div>
        <div className="content">
          <Grid fluid>
            <Row>
              <Col md={7}>
                <Card
                  title="Orders"
                  ctTableFullWidth
                  ctTableResponsive
                  content={
                    <Table striped hover>
                      <tbody>
                        {orders !== null
                          ? orders
                              .filter(
                                (p) =>
                                  p.dated.substr(0, p.dated.indexOf(" ")) ===
                                  this.state.salesDate
                              )
                              .map((prop, key) => {
                                return (
                                  <tr
                                    key={key}
                                    onClick={() => this.getItems(prop.id)}
                                  >
                                    {/* <td>{prop.order_status}</td> */}
                                    <td>
                                      {/* <strong>{prop.id}</strong> */}
                                      <strong>{prop.bill_no}</strong>
                                    </td>
                                    <td>{prop.username}</td>
                                    <td>
                                      {prop.dated.substr(
                                        0,
                                        prop.dated.indexOf(" ")
                                      )}
                                      <br />
                                      {prop.dated.substr(
                                        prop.dated.indexOf(" ") + 1
                                      )}
                                    </td>
                                    <td>{prop.items_count}</td>
                                    {/* <td>{prop.mobile}</td>
                                  <td>{prop.payment_status}</td> */}
                                    <td>{prop.payment_mode}</td>
                                    {/* <td>{prop.refund_status}</td> */}
                                    <td>₹{prop.final_amount}</td>
                                    <td>{prop.platform}</td>
                                    <td>{prop.aggregator}</td>

                                    {this.state.type === "9" &&
                                    storeUserRole === "SUPER_ADMIN" ? (
                                      <td>{prop.cancel_reason}</td>
                                    ) : null}
                                    {/* <td>Download</td>
                                  <form onsubmit="download(this['name'].value, this['text'].value)">
                                    <input
                                      type="text"
                                      name="name"
                                      value="test.txt"
                                    />
                                    <textarea name="text"></textarea>
                                    <input type="submit" value="Download" />
                                  </form> */}
                                  </tr>
                                );
                              })
                          : null}
                      </tbody>
                    </Table>
                  }
                />
              </Col>
              {this.state.onOrder === true ? (
                <Col md={5}>
                  <Card
                    title="Items"
                    ctTableFullWidth
                    ctTableResponsive
                    content={
                      <Table striped hover>
                        {items !== [] ? (
                          // items[0]
                          // .map((p, index) => {
                          //     return (
                          <tbody>
                            <tr>
                              {items !== [] ? (
                                items[0] !== undefined ? (
                                  <>
                                    {/* <td>{items[0].item.order_id}</td> */}
                                    {/* <td>{items[0].item.bil_no}</td> */}
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                  </>
                                ) : null
                              ) : null}
                              {/* <td>
                                <Button
                                  bsStyle="info"
                                  // pullleft={true}
                                  type="submit"
                                  // fill="true"
                                  onClick={this.track}
                                  style={{
                                    fontSize: "15px",
                                    // backgroundColor: "white",
                                  }}
                                >
                                  <span>Track</span>
                                  <i className="fa fa-binoculars fa-lg"></i>
                                </Button>
                              </td> */}
                            </tr>
                          </tbody>
                        ) : //   );
                        // })
                        null}
                        {/* {items !== []
                          ? items.map((p, index) => {
                              return (
                                <tbody key={index}>
                                  <tr>
                                    <td>
                                      <strong>{p.userinfo.name}</strong>
                                    </td>
                                    <td>
                                      <strong>{p.userinfo.phone}</strong>
                                    </td>
                                  </tr>
                                </tbody>
                              );
                            })
                          : null} */}
                        {items !== []
                          ? items.map((p, index) => {
                              // let allDealItems = JSON.parse(
                              //   p.item.dealItems !== (null || undefined)
                              //     ? p.item.dealItems.toString()
                              //     : ""
                              // );
                              // console.log(allDealItems.map((e) => e));
                              return (
                                <tbody key={index}>
                                  <tr>
                                    {/* <td>{p.item.order_id}</td> */}

                                    <td>
                                      <strong>{p.item.name}</strong>
                                    </td>
                                    <td>
                                      <strong>{p.item.quantity}</strong>
                                    </td>
                                    <td>
                                      <strong>
                                        {p.item.food_type === "VEG" ? (
                                          <i
                                            className="fa fa-dot-circle-o"
                                            style={styles.green}
                                          ></i>
                                        ) : (
                                          <i
                                            className="fa fa-dot-circle-o"
                                            style={styles.red}
                                          ></i>
                                        )}
                                      </strong>
                                    </td>
                                    <td>
                                      <strong>
                                        {p.item.size !== "NONE"
                                          ? p.item.size
                                          : null}
                                      </strong>
                                    </td>
                                    {/* <td>
                                      <strong>{p.item.sizeValue}</strong>
                                    </td> */}
                                    {p.offer_id !== "0" ? (
                                      <td>{p.offer_id}</td>
                                    ) : null}
                                    <td></td>
                                  </tr>
                                  <tr>
                                    {p.item.type === "deal" &&
                                    p.item.dealItems !==
                                      ([] || "" || {} || null || undefined) ? (
                                      <td>
                                        {JSON.parse(
                                          p.item.dealItems.toString()
                                        ).map((q) => (
                                          <>{q.name},</>
                                        ))}
                                      </td>
                                    ) : null}
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                  </tr>
                                  {p.addons !== []
                                    ? p.addons.map((q, index) => (
                                        <tr key={index}>
                                          <td>
                                            ({index + 1})-{q.name}
                                          </td>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                        </tr>
                                      ))
                                    : null}
                                </tbody>
                              );
                            })
                          : null}
                      </Table>
                    }
                  />
                  {items !== [] ? (
                    <>
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      {this.state.type === "7" ? (
                        <>
                          <label>
                            <input
                              type="radio"
                              id="dunzo"
                              name="assignee"
                              value="dunzo"
                              onChange={this.onFieldChange}
                            />
                            <span>&nbsp; Dunzo</span>
                          </label>
                          <label>
                            <input
                              type="radio"
                              id="house"
                              name="assignee"
                              value="house"
                              onChange={this.onFieldChange}
                            />
                            <span>&nbsp; In House</span>
                          </label>
                        </>
                      ) : null}
                      {this.state.type === "9" &&
                      storeUserRole === "SUPER_ADMIN" ? (
                        <Button
                          bsStyle="info"
                          // pullleft={true}
                          type="submit"
                          // fill="true"
                          onClick={() =>
                            // this.initiateCancel(this.state.oid)
                            this.CancelConfirm(this.state.oid)
                          }
                        >
                          <i
                            className="fa fa-ban fa-lg"
                            style={{ fontSize: "15px" }}
                          ></i>{" "}
                          <span style={{ fontSize: "15px", color: "red" }}>
                            Cancel Confirm
                          </span>
                        </Button>
                      ) : null}
                      {this.state.type === "4" ? (
                        <>
                          {storeUserRole === "SUPER_ADMIN" ? (
                            <Col md={12}>
                              <Card
                                title="Cancel"
                                ctTableFullWidth
                                ctTableResponsive
                                content={
                                  <Table striped hover>
                                    <tbody>
                                      <tr>
                                        <td>
                                          <label>
                                            <input
                                              type="radio"
                                              id="r1"
                                              name="cancelReason"
                                              value="Customer Not Interested"
                                              onChange={this.onFieldChange}
                                            />
                                            <span>
                                              &nbsp; Customer Not Interested
                                            </span>
                                          </label>
                                          <br />
                                          <label>
                                            <input
                                              type="radio"
                                              id="r2"
                                              name="cancelReason"
                                              value="Preperation Longtime"
                                              onChange={this.onFieldChange}
                                            />
                                            <span>
                                              &nbsp; Preperation Longtime
                                            </span>
                                          </label>
                                          <br />
                                          <label>
                                            <input
                                              type="radio"
                                              id="r2"
                                              name="cancelReason"
                                              value="Item Not available"
                                              onChange={this.onFieldChange}
                                            />
                                            <span>
                                              &nbsp; Item Not available
                                            </span>
                                          </label>
                                          <br />
                                          <label>
                                            <input
                                              type="radio"
                                              id="r2"
                                              name="cancelReason"
                                              value="Taste Not Good"
                                              onChange={this.onFieldChange}
                                            />
                                            <span>&nbsp; Taste Not Good</span>
                                          </label>
                                          <br />
                                          <label>
                                            <input
                                              type="radio"
                                              id="r2"
                                              name="cancelReason"
                                              value="Others"
                                              onChange={this.onFieldChange}
                                            />
                                            <span>&nbsp; Others</span>
                                          </label>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </Table>
                                }
                              />
                            </Col>
                          ) : null}
                          <br />
                          {storeUserRole === "SUPER_ADMIN" ? (
                            <>
                              <Button
                                bsStyle="info"
                                // pullleft={true}
                                type="submit"
                                // fill="true"
                                onClick={() =>
                                  // this.initiateCancel(this.state.oid)
                                  this.CancelOrder(this.state.oid)
                                }
                              >
                                <i
                                  className="fa fa-ban fa-lg"
                                  style={{ fontSize: "15px" }}
                                ></i>{" "}
                                <span
                                  style={{ fontSize: "15px", color: "white" }}
                                >
                                  Cancel Request
                                </span>
                              </Button>
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                              <span>
                                <Button
                                  bsStyle="info"
                                  // pullleft={true}
                                  type="submit"
                                  // fill="true"
                                >
                                  <ReactToPrint
                                    // onAfterPrint={this.refreshPage}
                                    trigger={() => (
                                      <span
                                      // href="#/"
                                      >
                                        <i
                                          className="fa fa-print"
                                          aria-hidden="true"
                                        ></i>
                                        &nbsp;print
                                      </span>
                                    )}
                                    content={() => this.componentRef2}
                                  />
                                </Button>
                              </span>
                            </>
                          ) : null}
                        </>
                      ) : null}
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      {this.state.type === "1" ||
                      this.state.type === "6" ||
                      this.state.type === "7" ||
                      this.state.type === "2" ||
                      this.state.type === "3" ? (
                        this.state.type === "7" ? (
                          <Button
                            bsStyle="info"
                            // pullleft={true}
                            type="submit"
                            // fill="true"
                            onClick={() => this.selectAssignee(this.state.oid)}
                            style={{
                              fontSize: "15px",
                              // backgroundColor: "white",
                            }}
                          >
                            <span>Accept</span>{" "}
                            <i className="fa fa-angle-double-right fa-lg"></i>
                          </Button>
                        ) : (
                          <Button
                            bsStyle="info"
                            // pullleft={true}
                            type="submit"
                            // fill="true"
                            onClick={() => this.ProceedOrder(this.state.oid)}
                            style={{
                              fontSize: "15px",
                              // backgroundColor: "white",
                            }}
                          >
                            <span>
                              {this.state.type === "1" ? <>Assign</> : null}
                              {this.state.type === "6" ? <>PickUp</> : null}
                              {this.state.type === "2" ? <>Deliver</> : null}
                              {this.state.type === "3" ? <>Complete</> : null}
                            </span>
                            <i className="fa fa-angle-double-right fa-lg"></i>
                          </Button>
                        )
                      ) : null}
                      {/* <br />
                      {this.state.cancel_reason === "" ? (
                        <>
                          <label>
                            <input
                              type="radio"
                              id="dunzo"
                              name="assignee"
                              value="dunzo"
                              onChange={this.onFieldChange}
                            />
                            <span>&nbsp; cancel Reason1</span>
                          </label>
                          <label>
                            <input
                              type="radio"
                              id="house"
                              name="assignee"
                              value="house"
                              onChange={this.onFieldChange}
                            />
                            <span>&nbsp;cancel Reason2</span>
                          </label>
                        </>
                      ) : null} */}
                    </>
                  ) : null}
                </Col>
              ) : null}
              {this.state.track === true ? (
                <Col md={5}>
                  <Card
                    title="Track"
                    ctTableFullWidth
                    ctTableResponsive
                    content={
                      <>
                        <Table striped hover>
                          <tbody>
                            {/* <tr>
                              <td>
                                <Button
                                  bsStyle="info"
                                  // pullleft={true}
                                  type="submit"
                                  // fill="true"
                                  onClick={this.goBackToOrder}
                                  style={{
                                    fontSize: "15px",
                                    float: "right",
                                    // backgroundColor: "white",
                                  }}
                                >
                                  <span>Go Back</span>
                                  <i className="fa fa-binoculars fa-lg"></i>
                                </Button>
                              </td>
                            </tr> */}
                            <tr>
                              <td>
                                <i className="fa fa-check-circle fa-lg"></i> 1
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <i className="fa fa-circle-o-notch fa-lg"></i> 2
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <i className="fa fa-circle-o-notch fa-lg"></i> 3
                              </td>
                            </tr>
                            {/* {orders !== null
                              ? orders.map((prop, key) => {
                                  return (
                                    <tr
                                      key={key}
                                      onClick={() => this.getItems(prop.id)}
                                    >
                                      <td>
                                        <strong>{prop.id}</strong>
                                      </td>
                                      <td>{prop.username}</td>
                                      <td>
                                        {prop.dated.substr(
                                          0,
                                          prop.dated.indexOf(" ")
                                        )}
                                        <br />
                                        {prop.dated.substr(
                                          prop.dated.indexOf(" ") + 1
                                        )}
                                      </td>
                                      <td>{prop.items_count}</td>
                                      <td>{prop.payment_mode}</td>
                                      <td>₹{prop.final_amount}</td>
                                      <td>{prop.platform}</td>
                                    </tr>
                                  );
                                })
                              : null} */}
                          </tbody>
                        </Table>
                      </>
                    }
                  />
                </Col>
              ) : null}
            </Row>
          </Grid>
        </div>
      </div>
    );
  }
  getPincodes = () => {
    var apiPath = new Uri(clientHost + PINCODE_LIST)
      // .addQueryParam("storeid", storeFresh.storeId)
      .addQueryParam("offset", 0)
      .addQueryParam("limit", 10);
    getResponseClient(
      apiPath,
      storeFresh.key,
      (onSuccessResponse) => {
        this.setState({ pincodes: onSuccessResponse.result });
      },
      (onError) => {
        console.log(onError.message);
      }
    );
  };
  async componentDidMount() {
    this.getPincodes();
    this.getItemwiseList();
    this.getDate();
    // var date = new Date().toISOString().slice(0, 10);
    // this.state.salesDate = date;
    // // this.getSalesByDate();
    // var apiPath = new Uri(
    //   // HOST +
    //   clientHost + ORDERS_BY_LOCATION
    // )
    //   // .addQueryParam("store_id", storeFresh.storeId)
    //   // .addQueryParam("offset", "0")
    //   // .addQueryParam("limit", "150")
    //   .addQueryParam("startDate", date)
    //   .addQueryParam("date", `${date}`)
    //   //  23:59:59
    //   .addQueryParam("order_status", "4")
    //   .addQueryParam("lat", storeFresh.latitute)
    //   .addQueryParam("log", storeFresh.longitude)
    //   .addQueryParam("km", `10`)
    //   .addQueryParam("pincode", this.state.pinCode);
    // getResponseClient(
    //   apiPath,
    //   storeFresh.key,
    //   (onSuccessResponse) => {
    //     this.setState({
    //       orders: onSuccessResponse.result,
    //       items: [],
    //       // type: onSuccessResponse.result[0].order_status,
    //     });
    //   },
    //   (onError) => {
    //     alert(onError.message);
    //   }
    // );
    // var date = new Date().toISOString().slice(0, 10);
    // var apiPath1 = new Uri(
    //   // HOST +
    //   clientHost +
    //     `Order/ordersByStoreId?store_id=${storeFresh.storeId}&order_status=4&offset=0&limit=150`
    // )
    //   .addQueryParam("startDate", date)
    //   .addQueryParam("date", `${date} 23:59:59`);
    // getResponseClient(
    //   apiPath1,
    //   storeFresh.key,
    //   (onSuccessResponse) => {
    //     this.setState(
    //       {
    //         orders: onSuccessResponse.result,
    //         // items: [],

    //         // type: onSuccessResponse.result[0].order_status,
    //       },
    //       //  this.getItemwiseList()
    //     );
    //   },
    //   (onError) => {
    //     alert(onError.message);
    //   }
    // );
  }

  // async componentDidMount() {
  //   this.getItemwiseList();
  // }
}
export default Orders1;

// 5 cancel confirm
// 8 rejected
// 9 cancel request
