import React from "react";
import SubCategoriesComponent from "./SubCategoriesComponent";
import {
  CATEGORY_LIST,
  SUBCATEGORY_LIST,
  ADD_SUBCATEGORY,
  DELETE_SUBCATEGORY,
} from "../../Network/ApiPaths";
import Uri from "../../Network/Uri";
import postClientDataResponse from "../../Network/PostClientDataResponse";
import getResponseClient from "../../Network/GetClientDataResponse";
let storeFresh;
let clientHost;
class SubCategoriesContainer extends React.Component {
  constructor(props) {
    super();
    const client = window.localStorage.getItem("clientAccess");
    storeFresh = JSON.parse(client);
    if (storeFresh !== null) {
      clientHost = JSON.parse(window.localStorage.getItem("clientAccess")).host;
    }
    this.state = {
      subCategories: [],
      categories: [],
      selectedCatId: "",
      selectedSubCatId: "",
      collapse: false,
      cName: "all",
      // onUpdateDetails: "",
      name: "",
      store: "",
      type: "",
      description: "",
      tax: "",
      itemDefaultDetails: "",
      itemDefaultPrices: "",
      newName: "",
      newStore: "",
      newType: "",
      newDesc: "",
      newTax: "",
      updatedName: "",
      updatedStore: "",
      updatedType: "",
      updatedDesc: "",
      updatedTax: "",
      dID: "",
      dPID: "",
      newPriceQuantity: "",
      newPriceMeasure: "",
      newPrice: "",
      newPriceOffer: "",
      newPID: "",
      priceQuantity: "",
      priceMeasure: "",
      pricePrice: "",
      priceOffer: "",
      new: false,
      deletedSubCat: false,
      editCat:false,
    };
  }
  selectedCatID = (sCatId, sCatName) => {
    this.setState({
      selectedCatId: sCatId,
      cName: sCatName,
      collapse: true,
    });
    this.getSubCategories(sCatId);
  };
  next = (offset) => {
    if (this.state.selectedCatId === "") {
      this.setState({
        subCategories: [],
      });
    } else {
      var apiPath = new Uri(
        clientHost +
          `Category/listSubCategoriesAdmin?category_id=${this.state.selectedCatId}&limit=10&offset=${offset}`
      );
      getResponseClient(
        apiPath,
        (onSuccessResponse) => {
          this.setState({
            subCategories: onSuccessResponse.result,
          });
        },
        (onError) => {
          alert(onError.message);
        }
      );
    }
  };

  fieldChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
    // this.setState({ [key]: e.target.value });
  };
  onUpdateDetails = (e) => {
    alert(
      this.state.itemDefaultDetails +
        this.state.updatedName +
        this.state.updatedStore +
        this.state.updatedType +
        this.state.updatedDesc +
        this.state.updatedTax
    );
  };

  onSubCategoryDelete = (e) => {
    var apiPath = new Uri(
      clientHost + `Menu/deleteSubCategory?id=${this.state.dID}`
    );
    getResponseClient(
      apiPath,
      storeFresh.key,
      (onSuccessResponse) => {
        alert(onSuccessResponse);
      },
      (onError) => {
        console.log(onError.message);
      }
    );
  };

  onSubCategoryDetails = (e) => {
    console.log(e);
    // e.preventDefault();
    this.setState({
      itemDefaultDetails: e,
    });
  };
  onNameUpdate = (e) => {
    this.setState({ newName: e.target.value });
  };
  onAddNewSubCategory = () => {
    if (
      this.state.newName === "" ||
      this.state.newName === null ||
      this.state.newName === undefined
    ) {
      alert("Enter Name");
    } else {
      // let subCategoryDet = this.state.itemDefaultDetails;
      // POST API WITH DATA
      const requestBody = {
        category_id: this.state.selectedCatId,
        name: this.state.newName,
        store_id: this.state.newStore,
      };
      postClientDataResponse(
        clientHost + ADD_SUBCATEGORY,
        storeFresh.key,
        requestBody,
        (onSuccessResponse) => {
          // alert(onSuccessResponse);
          // console.log(onSuccessResponse);
          this.getSubCategories(this.state.selectedCatId);
        },
        (onError) => {
          console.log(onError);
        }
      );
    }
  };
  getSubCategories = (selectedCatId) => {
    var apiPath = new Uri(clientHost + SUBCATEGORY_LIST)
      .addQueryParam("catId", selectedCatId)
      .addQueryParam("store_id", storeFresh.storeId);
    getResponseClient(
      apiPath,
      storeFresh.key,
      (onSuccessResponse) => {
        // console.log(
        //   onSuccessResponse.result.filter((q) => q.info.category_id === sid)
        // );
        // if (onSuccessResponse.status === true) {
        // this.setState({
        //   subCategories: onSuccessResponse.result,
        //   // collapse: false,
        //   // new: true,
        // });
        // } else {
        this.setState({
          subCategories: onSuccessResponse.result,
          collapse: false,
          new: true,
        });
        //    }
      },
      (onError) => {
        console.log(onError.message);
      }
    );
  };
  // getSubCategories = (sid) => {
  //   var apiPath = new Uri(clientHost + SUBCATEGORY_LIST)
  //     .addQueryParam("catId", sid)
  //     .addQueryParam("store_id", storeFresh.storeId);
  //   getResponseClient(
  //     apiPath,
  //     storeFresh.key,
  //     (onSuccessResponse) => {
  //       this.setState({
  //         categories:onSuccessResponse.result.filter(
  //           (q) => q.info.category_id === sid),
  //         subCategories:onSuccessResponse.result.filter(
  //           (q) => q.info.category_id === sid),
  //             collapse:false,
  //             new:true,
  //       })

  //     },
  //     (onError) => {
  //       console.log(onError.message);
  //     }
  //   );
  // };
  onSubCategoryTriggerEdit = (p) => {
    this.setState({
      selectedCat: p,
    });
  };
  editCat = (c) => {
    if (
      this.state.newName === "" ||
      this.state.newName === this.state.selectedCat.name
    ) {
      alert("no changes to update");
    } else {
      let cid = this.state.selectedCat;
      const requestBody = {
        name: this.state.newName,
        addon_status: "ACTIVE",
        store_id: storeFresh.storeId,
        id: cid.id,
      };
      postClientDataResponse(
        clientHost + ADD_SUBCATEGORY,
        storeFresh.key,
        requestBody,
        (onSuccessResponse) => {
          this.setState({
            editedCat: true,
          });
          this.getSubCategories(this.state.selectedCatId);
        },
        (onError) => {
          console.log(onError);
        }
      );
    }
  };
  onSubCategoryTriggerDelete = (e) => {
    this.setState({
      dID: e,
    });
  };
  deleteSubCat = (e) => {
    this.setState({
      SubCatDeleted: true,
    });
    var apiPath = new Uri(clientHost + DELETE_SUBCATEGORY).addQueryParam(
      "id",
      this.state.dID
    );
    // .addQueryParam(
    //   this.state.dID )
    getResponseClient(
      apiPath,
      storeFresh.key,
      (onSuccessResponse) => {
        // this.setState({
        //   deletedCat: true,
        // });
        this.getSubCategories(this.state.selectedCatId);
      },
      (onError) => {
        console.log(onError.message);
      }
    );
  };
  render() {
    return (
      <div>
        {/* {test} */}
        <SubCategoriesComponent
          data={this.state.subCategories}
          catData={this.state.categories}
          selectedCat={this.selectedCatID}
          catid={this.state.selectedCatId}
          cname={this.state.cName}
          collapse={this.state.collapse}
          next={this.next}
          fieldChange={this.fieldChange}
          onUpdateDetails={this.onUpdateDetails}
          onSubCategoryDetails={this.onSubCategoryDetails}
          onSubCategoryDelete={this.onSubCategoryDelete}
          subDefaultDetails={this.state.itemDefaultDetails}
          addNewSubCategory={this.onAddNewSubCategory}
          onSubCategoryTriggerEdit={this.onSubCategoryTriggerEdit}
          onSubCategoryTriggerDelete={this.onSubCategoryTriggerDelete}
          new={this.state.new}
          deletedSubCat={this.deletedSubCat}
          deleteSubCat={this.deleteSubCat}
          update={this.editCat}
          onNameUpdate={this.onNameUpdate}
          editedCat={this.state.editedCat}
        />
      </div>
    );
  }
  async componentDidMount() {
    var apiPath = new Uri(clientHost + CATEGORY_LIST).addQueryParam(
      "storeid",
      storeFresh.storeId
    );
    getResponseClient(
      apiPath,
      storeFresh.key,
      (onSuccessResponse) => {
        this.setState({
          categories: onSuccessResponse.result,
        });
      },
      (onError) => {
        console.log(onError.message);
      }
    );
  }
}

export default SubCategoriesContainer;
