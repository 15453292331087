import React, { Component } from "react";
import { Grid, Row, Col, Table } from "react-bootstrap";
import Card from "../Card/Card";
// import { thArray, tdArray } fsrom "variables/Variables.jsx";
// import Button from "components/CustomButton/CustomButton.jsx";
import Toggle from "react-toggle";
import {
  //  HOST,
  CATEGORY_LIST,
  MENU_LIST,
  DEALS_LIST,
  NEW_DEAL,
  NEW_DEAL_DETAIL,
  DEAL_DETAILS,
  IMG_PATH_CATEGORY,
} from "../../Network/ApiPaths";
import imgsrc from "../../images/img.png";
import { Image } from "react-bootstrap";
import Uri from "../../Network/Uri";
// import getResponse from "../../Network/GetDataResponse";
// import postDataResponse from "../../Network/PostDataResponse";
import Modal from "react-modal";
import Zoom from "react-reveal/Zoom";

import postClientDataResponse from "../../Network/PostClientDataResponse";
import getResponseClient from "../../Network/GetClientDataResponse";
let clientHost;
let storeFresh;
let storeIdValue;
// const userCheck = window.localStorage.getItem("access");
// let storeDet = JSON.parse(userCheck);
// let storeIdValue;
// if (storeDet !== null) {
//   storeIdValue = storeDet.storeId;
// } else {
//   storeIdValue = "";
// }
class Deals extends Component {
  constructor(props) {
    super();
    const client = window.localStorage.getItem("clientAccess");
    storeFresh = JSON.parse(client);
    if (storeFresh !== null) {
      storeIdValue = storeFresh.storeId;
      clientHost = JSON.parse(window.localStorage.getItem("clientAccess")).host;
      // storeCode = JSON.parse(window.localStorage.getItem("clientAccess"))
      //   .storeCode;
      // storeName = JSON.parse(window.localStorage.getItem("clientAccess"))
      //   .storeName;
    }
    this.state = {
      newDeal: false,
      newDealDetail: false,
      deals: [],
      date: "",
      // orders: [],
      // type: "",
      // onOrder: false,
      title: "",
      description: "",
      type: "",
      customize: "",
      catsSelected: [],
      itemsSelected: [],
      food: "",
      menu: "",
      measure: "",
      maxPrice: "",
      categories: "",
      getItems: "",
      items: "",
      dealsDetails: false,
      dealDetails: [],
      selectedDealId: "",
      sDate: "",
      eDate: "",
      pidOne: "",
      pidTwo: "",
      searchValue: "",
    };
  }
  selectFreeItem = () => {
    this.setState({
      free1: "",
    });
  };
  onFoodChange = (e) => {
    this.setState({
      food: e.target.value,
    });
  };
  onMenuChange = (e) => {
    this.setState({
      menu: e.target.value,
    });
  };
  onMeasureChange = (e) => {
    this.setState({
      measure: e.target.value,
    });
  };
  onFieldChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  onCat = (e) => {
    e.preventDefault();
    var apiPath = new Uri(clientHost + CATEGORY_LIST).addQueryParam(
      "storeid",
      storeIdValue
    );
    getResponseClient(
      apiPath,
      storeFresh.key,
      (onSuccessResponse) => {
        this.setState({
          categories: onSuccessResponse.result,
        });
      },
      (onError) => {
        console.log(onError.message);
      }
    );
  };
  onItem = (e) => {
    e.preventDefault();
    this.setState({
      items: "required",
    });
  };
  getItems = (e) => {
    this.setState({
      getItems: [],
    });
    // e.preventDefault();
    var apiPath = new Uri(clientHost + MENU_LIST).addQueryParam(
      "category_id",
      e
    );
    getResponseClient(
      apiPath,
      storeFresh.key,
      (onSuccessResponse) => {
        this.setState({
          getItems: onSuccessResponse.result,
        });
      },
      (onError) => {
        console.log(onError.message);
      }
    );
  };
  onDeal = (e) => {
    e.preventDefault();
    this.setState({
      title: this.state.title,
      description: this.state.description,
      type: this.state.type,
      customize: this.state.customize,
      catsSelected: this.state.catsSelected,
      itemsSelected: this.state.itemsSelected,
      food: this.state.food,
      menu: this.state.menu,
      measure: this.state.measure,
      maxPrice: this.state.maxPrice,
    });
    this.validate();
  };
  validate = () => {
    if (
      this.state.name === null ||
      this.state.name === "" ||
      this.state.name === undefined
    ) {
      alert("name Error");
    } else if (
      this.state.price === null ||
      this.state.price === "" ||
      this.state.price === undefined
    ) {
      alert("price Error");
    } else if (
      this.state.description === null ||
      this.state.description === "" ||
      this.state.description === undefined
    ) {
      alert("description Error");
    }
    // else if (
    //   this.state.customize === null ||
    //   this.state.customize === "" ||
    //   this.state.customize === undefined
    // ) {
    //   alert("customize Error");
    // }
    else if (
      this.state.type === null ||
      this.state.type === "" ||
      this.state.type === undefined
    ) {
      alert("type Error");
    }
    // else if (
    //   this.state.duration === "LIMITED" &&
    //   (this.state.sDate === null ||
    //     this.state.sDate === "" ||
    //     this.state.sDate === undefined)
    // ) { alert("start_date Error"); } else if (
    //   this.state.duration === "LIMITED" &&
    //   (this.state.eDate === null ||
    //     this.state.eDate === " " ||
    //     this.state.eDate === undefined)
    // ) { alert("end_date Error"); }
    // else if (
    //   this.state.duration === null ||
    //   this.state.duration === "" ||
    //   this.state.duration === undefined
    // ) { alert("duration error"); }
    else if (
      this.state.pidOne === null ||
      this.state.pidOne === "" ||
      this.state.pidOne === undefined
    ) {
      alert("pidOne error");
    } else if (
      this.state.pidTwo === null ||
      this.state.pidTwo === "" ||
      this.state.pidTwo === undefined
    ) {
      alert("pidTwo error");
    } else {
      this.postNewDeal();
    }
  };
  postNewDeal = () => {
    // let dArr1 = this.state.sDate.split("-");
    // let dS = dArr1[1] + "/" + dArr1[2] + "/" + dArr1[0];
    // let dArr2 = this.state.eDate.split("-");
    // let dE = dArr2[1] + "/" + dArr2[2] + "/" + dArr2[0];
    // let dt1 = new Date(dS);
    // let dt2 = new Date(dE);
    // let durationValue = Math.floor(
    //   (Date.UTC(dt2.getFullYear(), dt2.getMonth(), dt2.getDate()) -
    //     Date.UTC(dt1.getFullYear(), dt1.getMonth(), dt1.getDate())) /
    //     (1000 * 60 * 60 * 24)
    // );
    let requestBody;
    if (this.state.duration !== "NONE" || "") {
      requestBody = {
        name: this.state.name,
        price: this.state.price,
        description: this.state.description,
        type: this.state.type,
        customize: this.state.customize === "" ? "NO" : this.state.customize,
        complementary_pid_one: this.state.pidOne,
        complementary_pid_two: this.state.pidTwo,
        store_id: storeIdValue,
        created_date: this.state.date,
        duration: this.state.duration,
        startDate:
          this.state.sDate === (null || "" || undefined)
            ? this.state.date
            : this.state.sDate,
        endDate:
          this.state.eDate === (null || "" || undefined)
            ? this.state.date
            : this.state.eDate,
        status: "ACTIVE",
      };
    } else {
      requestBody = {
        name: this.state.name,
        price: this.state.price,
        description: this.state.description,
        type: this.state.type,
        customize: this.state.customize === "" ? "NO" : this.state.customize,
        complementary_pid_one: this.state.pidOne,
        complementary_pid_two: this.state.pidTwo,
        store_id: storeIdValue,
        created_date: this.state.date,
        duration: this.state.duration,
        status: "ACTIVE",
      };
    }
    postClientDataResponse(
      clientHost + NEW_DEAL,
      storeFresh.key,
      requestBody,
      (onSuccessResponse) => {
        this.setState({
          price: "",
          description: "",
          type: "",
          customize: "",
          // catsSelected: [],
          // itemsSelected: [],
        });
        this.myFormRef.reset();
      },
      (onError) => {
        console.log(onError);
      }
    );
  };
  onDealDetail = (e) => {
    e.preventDefault();
    this.setState({
      title: this.state.title,
      description: this.state.description,
      type: this.state.type,
      customize: this.state.customize,
      catsSelected: this.state.catsSelected,
      itemsSelected: this.state.itemsSelected,
      food: this.state.food,
      menu: this.state.menu,
      measure: this.state.measure,
      maxPrice: this.state.maxPrice,
    });
    this.validate2();
  };
  validate2 = () => {
    if (
      this.state.title === null ||
      this.state.title === "" ||
      this.state.title === undefined
    ) {
      alert("title Error");
    } else if (
      this.state.catsSelected === null ||
      this.state.catsSelected === [] ||
      this.state.catsSelected.length === 0 ||
      this.state.catsSelected === "" ||
      this.state.catsSelected === undefined
    ) {
      alert("catsSelected Error");
    } else if (
      this.state.itemsSelected === null ||
      this.state.itemsSelected === [] ||
      this.state.itemsSelected.length === 0 ||
      this.state.itemsSelected === "" ||
      this.state.itemsSelected === undefined
    ) {
      alert("itemsSelected Error");
    } else if (
      this.state.food === null ||
      this.state.food === "" ||
      this.state.food === undefined
    ) {
      alert("food Error");
    } else if (
      this.state.menu === null ||
      this.state.menu === "" ||
      this.state.menu === undefined
    ) {
      alert("menu Error");
    } else if (
      this.state.measure === null ||
      this.state.measure === "" ||
      this.state.measure === undefined
    ) {
      alert("measure Error");
    } else if (
      this.state.maxPrice === null ||
      this.state.maxPrice === "" ||
      this.state.maxPrice === undefined
    ) {
      alert("maxPrice Error");
    } else if (
      this.state.extraPrice === null ||
      this.state.extraPrice === "" ||
      this.state.extraPrice === undefined
    ) {
      alert("extraPrice Error");
    } else {
      alert(
        this.state.title +
          this.state.catsSelected.map((p) => p.name) +
          this.state.itemsSelected.map((p) => p.name) +
          this.state.food +
          this.state.menu +
          this.state.measure +
          this.state.maxPrice +
          this.state.extraPrice
      );
      this.postNewDealDetail();
    }
  };
  postNewDealDetail = () => {
    const requestBody = {
      deal_id: this.state.selectedDealId,
      title: this.state.title,
      categories: this.state.catsSelected.map((p) => p.id).toString(),
      items: this.state.itemsSelected.map((p) => p.info.id).toString(),
      food_type: this.state.food,
      menu_type: this.state.menu,
      measure: this.state.measure,
      max_price: this.state.maxPrice,
      extra_price: this.state.extraPrice,
      status: "ACTIVE",
    };
    postClientDataResponse(
      clientHost + NEW_DEAL_DETAIL,
      storeFresh.key,
      requestBody,
      (onSuccessResponse) => {
        this.setState({
          // price: "",
          // description: "",
          // type: "",
          // catsSelected: [],
          // itemsSelected: [],
          selectedDealId: "",
        });
        this.myFormRef2.reset();
      },
      (onError) => {
        alert(onError);
      }
    );
  };
  onChangeDealStatus = (dd) => {
    const requestBody = {
      id: dd.id,
      status: "INACTIVE",
    };
    postClientDataResponse(
      clientHost + NEW_DEAL,
      storeFresh.key,
      requestBody,
      (onSuccessResponse) => {
        this.getDeals();
      },
      (onError) => {
        console.log(onError);
      }
    );
  };
  closeModal = () => {
    this.setState({ categories: null, items: null, getItems: "" });
  };
  handleAddedCat = (p) => {
    this.setState({
      catsSelected: [...this.state.catsSelected, p],
    });
  };
  handleAddedItem = (p) => {
    this.setState({
      itemsSelected: [...this.state.itemsSelected, p],
    });
  };
  // setAddedCats = () => {
  //   this.setState({
  //     catsSelected: this.state.catsSelected,
  //   });
  // };
  onNewDeal = () => {
    this.setState({
      newDeal: true,
    });
  };
  goBack = () => {
    this.setState({
      newDeal: false,
    });
  };
  goToDeals = () => {
    this.setState({
      dealsDetails: false,
      dealDetails: [],
    });
  };
  onDealsDetails = (did) => {
    this.setState({
      dealsDetails: true,
      selectedDealId: did,
    });
    var apiPath = new Uri(clientHost + DEAL_DETAILS)
      // .addQueryParam("storeid", storeIdValue)
      .addQueryParam("dealId", did);
    getResponseClient(
      apiPath,
      storeFresh.key,
      (onSuccessResponse) => {
        this.setState({
          dealDetails: onSuccessResponse.result,
        });
      },
      (onError) => {
        console.log(onError.message);
      }
    );
  };
  onNewDealDetail = () => {
    this.setState({
      newDealDetail: true,
    });
  };
  goBackToDetails = () => {
    this.setState({
      newDealDetail: false,
    });
  };
  getDate = () => {
    var date = new Date().toISOString().slice(0, 10);
    this.setState({ date });
  };
  getDeals = () => {
    var apiPath = new Uri(clientHost + DEALS_LIST).addQueryParam(
      "storeid",
      storeIdValue
    );
    getResponseClient(
      apiPath,
      storeFresh.key,
      (onSuccessResponse) => {
        this.setState({
          deals: onSuccessResponse.result,
        });
      },
      (onError) => {
        console.log(onError.message);
      }
    );
  };
  // searchInitiate = (iName) => {
  //   this.setState({ searchValue: iName });
  //   this.onSearch(iName);
  // };
  // onSearch = (itemName) => {
  //   let allProducts = this.state.products;
  //   const filteredData = allProducts.filter((product) => {
  //     let info = product.info;
  //     return (
  //       info.name.toLowerCase().includes(itemName.toLowerCase()) &&
  //       info.store_id === storeIdValue
  //     );
  //   });
  //   this.setState({ filteredProducts: filteredData });
  // };
  render() {
    const styles = {
      imageMenu: { margin: "0 0 0 10px", width: "40px", height: "40px" },
    };
    // console.log(JSON.parse(storeDet));
    const { categories } = this.state;
    const { items } = this.state;
    const { deals } = this.state;
    const { newDeal } = this.state;
    const { dealsDetails } = this.state;
    const { dealDetails } = this.state;
    const { newDealDetail } = this.state;
    return (
      <div>
        {dealsDetails !== true ? (
          <>
            {newDeal !== true ? (
              <div className="content">
                <Grid fluid>
                  <Row>
                    <Col md={12}>
                      <Card
                        title="Striped Table with Hover"
                        category="Here is a subtitle for this table"
                        ctTableFullWidth
                        ctTableResponsive
                        content={
                          <>
                            <div className="header">
                              <h4 className="title">Deals List</h4>
                            </div>
                            <button
                              style={{ float: "right" }}
                              onClick={this.onNewDeal}
                            >
                              New Deal
                            </button>
                            <Table striped hover>
                              <thead>
                                <tr>
                                  <th>Status</th>
                                  <th>Name</th>
                                  <th>Description</th>
                                  <th>Price</th>
                                  <th>Type</th>
                                  <th>Start</th>
                                  <th>End</th>
                                  <th>Duration</th>
                                  <th>Free</th>
                                  <th>Customize</th>
                                  <th>Image</th>
                                </tr>
                              </thead>
                              <tbody>
                                {deals
                                  ? deals !== []
                                    ? deals.map((p, index) => {
                                        return (
                                          <tr
                                            key={index}
                                            // onClick={() =>
                                            //     this.onDealsDetails(p.id) }
                                          >
                                            <td>
                                              <Toggle
                                                defaultChecked={
                                                  p.status === "ACTIVE"
                                                    ? true
                                                    : false
                                                }
                                                onChange={() =>
                                                  this.onChangeDealStatus(p)
                                                }
                                              />
                                            </td>
                                            <td
                                              onClick={() =>
                                                this.onDealsDetails(p.id)
                                              }
                                            >
                                              <strong>{p.name}</strong>
                                            </td>
                                            {p.description.length > 12 ? (
                                              <td>
                                                {p.description.substring(0, 11)}
                                                ...
                                              </td>
                                            ) : (
                                              <td>{p.description}</td>
                                            )}
                                            <td>{p.price}₹</td>
                                            <td>{p.type}</td>
                                            <td>{p.start_date}</td>
                                            <td>{p.end_date}</td>
                                            <td>{p.duration}</td>
                                            <td>
                                              {p.complementary_pid_one !== "0"
                                                ? p.complementary_pid_one
                                                : null}{" "}
                                              {p.complementary_pid_two !== "0"
                                                ? p.complementary_pid_two
                                                : null}
                                            </td>
                                            <td>{p.customize}</td>
                                            <td>
                                              <Image
                                                roundedcircle="true"
                                                src={
                                                  p.image_path !== ""
                                                    ? IMG_PATH_CATEGORY +
                                                      `/` +
                                                      p.id +
                                                      "/" +
                                                      p.image_path
                                                    : imgsrc
                                                }
                                                style={styles.imageMenu}
                                                // onClick={() =>
                                                //   this.addToCart(prop)
                                                // }
                                              ></Image>
                                              {/* {p.image_path} */}
                                            </td>
                                          </tr>
                                        );
                                      })
                                    : null
                                  : null}
                              </tbody>
                            </Table>
                          </>
                        }
                      />
                    </Col>
                  </Row>
                </Grid>
              </div>
            ) : (
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-close">
                    <div className="dropdown">
                      <button
                        type="button"
                        id="closeCard5"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                        className="dropdown-toggle"
                      >
                        <i className="fa fa-ellipsis-v"></i>
                        <span onClick={this.goBack}>Go Back</span>
                      </button>
                      <div
                        aria-labelledby="closeCard5"
                        className="dropdown-menu dropdown-menu-right has-shadow"
                      >
                        <a href="#profile" className="dropdown-item remove">
                          <i className="fa fa-times"></i>Close
                        </a>
                        <a href="#profile" className="dropdown-item edit">
                          <i className="fa fa-gear"></i>Edit
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="card-header d-flex align-items-center">
                    <h3 className="h4">Create Deal</h3>
                  </div>
                  <div className="card-body">
                    <form
                      className="form-horizontal"
                      ref={(el) => (this.myFormRef = el)}
                    >
                      <div className="form-group row">
                        <label className="col-sm-3 form-control-label">
                          Name
                        </label>
                        <div className="col-sm-9">
                          <input
                            type="text"
                            className="form-control form-control-lg"
                            placeholder="provide name"
                            name="name"
                            onChange={this.onFieldChange}
                          />
                        </div>
                      </div>
                      <div className="line"></div>
                      <div className="form-group row">
                        <label className="col-sm-3 form-control-label">
                          Price
                        </label>
                        <div className="col-sm-9">
                          <input
                            type="text"
                            className="form-control form-control-lg"
                            placeholder="provide price"
                            name="price"
                            onChange={this.onFieldChange}
                          />
                        </div>
                      </div>
                      <div className="line"></div>
                      <div className="form-group row">
                        <label className="col-sm-3 form-control-label">
                          Description
                        </label>
                        <div className="col-sm-9">
                          <input
                            type="text"
                            className="form-control form-control-lg"
                            placeholder="provide description"
                            onChange={this.onFieldChange}
                            name="description"
                          />
                        </div>
                      </div>
                      <div className="line"></div>
                      <div className="form-group row">
                        <label className="col-sm-3 form-control-label">
                          Customize
                        </label>
                        <div className="col-sm-9">
                          <select
                            className="form-control mb-3"
                            name="customize"
                            onChange={this.onFieldChange}
                            defaultValue="NO"
                          >
                            <option value="YES">YES</option>
                            <option value="NO">NO</option>
                          </select>
                        </div>
                      </div>
                      <div className="line"></div>
                      <div className="form-group row">
                        <label className="col-sm-3 form-control-label">
                          Type
                        </label>
                        <div className="col-sm-9">
                          <select
                            className="form-control mb-3"
                            name="type"
                            onChange={this.onFieldChange}
                            // defaultValue="BUY_ONE_GET_ONE"
                          >
                            <option value="" hidden>
                              select type
                            </option>
                            <option value="BUY_ONE_GET_ONE">
                              BUY ONE GET ONE
                            </option>
                            <option value="MEAL_DEALS">MEAL DEALS</option>
                            <option value="BUNDLE">BUNDLE</option>
                            <option value="DOD">DOD</option>
                            <option value="WEEKLY_DEALS">WEEKLY DEALS</option>
                            <option value="BIG_SAVINGS">BIG SAVINGS</option>
                          </select>
                        </div>
                      </div>
                      <div className="line"></div>
                      <div className="form-group row">
                        <label className="col-sm-3 form-control-label">
                          Duration
                        </label>
                        <div className="col-sm-9">
                          <select
                            className="form-control mb-3"
                            name="duration"
                            onChange={this.onFieldChange}
                            defaultValue="NONE"
                          >
                            <option value="NONE">NONE</option>
                            <option value="LIMITED">LIMITED</option>
                          </select>
                        </div>
                      </div>
                      <div className="line"></div>
                      {this.state.duration === "LIMITED" ? (
                        <>
                          <div className="form-group row">
                            <label className="col-sm-3 form-control-label">
                              Start Date
                            </label>
                            <div className="col-sm-3">
                              <input
                                type="date"
                                className="form-control form-control-lg"
                                placeholder="provide Start Date"
                                defaultValue={this.state.date}
                                onChange={this.onFieldChange}
                                name="sDate"
                                min={this.state.date}
                              />
                            </div>
                          </div>
                          <div className="line"></div>
                          <div className="form-group row">
                            <label className="col-sm-3 form-control-label">
                              End Date
                            </label>
                            <div className="col-sm-3">
                              <input
                                type="date"
                                className="form-control form-control-lg"
                                placeholder="provide End Date"
                                // defaultValue={
                                //   // this.state.sDate === ""
                                //   //   ? this.state.date
                                //   //   : this.state.sDate
                                //   //////
                                //   this.state.sDate === ""
                                //     ? this.state.date
                                //     : this.state.eDate === ""
                                //     ? this.state.sDate
                                //     : this.state.eDate
                                // }
                                value={
                                  this.state.sDate === ""
                                    ? this.state.date
                                    : this.state.eDate === ""
                                    ? this.state.sDate
                                    : // this.state.sDate !== this.state.date ? null :
                                    new Date(this.state.eDate) <=
                                      new Date(this.state.sDate)
                                    ? this.state.sDate
                                    : this.state.eDate
                                }
                                onChange={this.onFieldChange}
                                name="eDate"
                                min={
                                  this.state.sDate === ""
                                    ? this.state.date
                                    : this.state.sDate
                                }
                              />
                            </div>
                          </div>
                          <div className="line"></div>
                        </>
                      ) : null}
                      <div className="form-group row">
                        <label className="col-sm-3 form-control-label">
                          complementary Item One
                        </label>
                        <div className="col-sm-9">
                          <input
                            type="text"
                            className="form-control form-control-lg"
                            placeholder="provide price"
                            name="pidOne"
                            onChange={this.onFieldChange}
                            // onChange={this.selectFreeItem}
                          />
                          {/* <input
                            onChange={(e) =>
                              this.searchInitiate(e.target.value)
                            }
                            type="search"
                            placeholder="Enter Product Name..."
                            className="form-control"
                            name="searchValue"
                            value={this.state.searchValue}
                          /> */}
                        </div>
                      </div>
                      <div className="line"></div>
                      <div className="form-group row">
                        <label className="col-sm-3 form-control-label">
                          complementary Item Two
                        </label>
                        <div className="col-sm-9">
                          <input
                            type="text"
                            className="form-control form-control-lg"
                            placeholder="provide price"
                            name="pidTwo"
                            onChange={this.onFieldChange}
                          />
                        </div>
                      </div>
                      <div className="line"></div>
                      <div className="form-group row">
                        <div className="col-sm-4 offset-sm-3">
                          <button type="submit" className="btn btn-secondary">
                            Cancel
                          </button>
                          <span> </span>
                          <button
                            // type="submit"
                            className="btn btn-primary"
                            onClick={this.onDeal}
                          >
                            Save changes
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>

                <div className="content">
                  {categories && (
                    <Modal
                      isOpen={true}
                      onRequestClose={this.closeModal}
                      ariaHideApp={false}
                      className="Modal"
                      // scrollable={true}
                    >
                      <Zoom scrollable={true}>
                        <Card
                          content={
                            <div className="table-full-width">
                              <table className="table">
                                <tbody>
                                  {categories
                                    ? categories.map((p, index) => (
                                        <tr key={index}>
                                          {/* <tr> */}
                                          <td>
                                            <div className="checkbox checkbox-inline">
                                              <input
                                                id={p.id}
                                                type="checkbox"
                                                onChange={() =>
                                                  this.handleAddedCat(p)
                                                }
                                              />
                                              <label htmlFor={p.id}>
                                                {p.name}
                                              </label>
                                            </div>
                                          </td>
                                        </tr>
                                      ))
                                    : null}
                                </tbody>
                              </table>
                              <table className="table">
                                <tbody>
                                  <tr></tr>
                                </tbody>
                              </table>
                              <span> </span>
                              <span> </span>
                              {/* <Button
                        bsStyle="info"
                        pullright="true"
                        fill="true"
                        type="submit"
                        onClick={this.setAddedCats}
                      >
                        Add cats
                      </Button> */}
                            </div>
                          }
                        />
                      </Zoom>
                    </Modal>
                  )}
                </div>
                <div className="content">
                  {items && (
                    <Modal
                      isOpen={true}
                      onRequestClose={this.closeModal}
                      ariaHideApp={false}
                      className="Modal"
                      // scrollable={true}
                    >
                      <Zoom scrollable={true}>
                        <Card
                          content={
                            <div className="table-full-width">
                              <table className="table">
                                <thead>
                                  <tr>
                                    {this.state.catsSelected.map((p, index) => (
                                      <th
                                        key={index}
                                        onClick={() => this.getItems(p.id)}
                                      >
                                        {p.name}
                                      </th>
                                    ))}
                                  </tr>
                                </thead>
                                <tbody>
                                  {this.state.getItems
                                    ? this.state.getItems.map((p, index) => (
                                        <tr key={index}>
                                          <td>
                                            <div className="checkbox checkbox-inline">
                                              <input
                                                id={p.info.id}
                                                type="checkbox"
                                                onChange={() =>
                                                  this.handleAddedItem(p)
                                                }
                                              />
                                              <label htmlFor={p.info.id}>
                                                {p.info.name}
                                              </label>
                                            </div>
                                          </td>
                                        </tr>
                                      ))
                                    : null}
                                </tbody>
                              </table>
                              <table className="table">
                                <tbody>
                                  <tr></tr>
                                </tbody>
                              </table>
                              <span> </span>
                              <span> </span>
                            </div>
                          }
                        />
                      </Zoom>
                    </Modal>
                  )}
                </div>
              </div>
            )}
          </>
        ) : (
          <>
            {newDealDetail === false ? (
              <div className="content">
                <Grid fluid>
                  <Row>
                    <Col md={12}>
                      <Card
                        title="Striped Table with Hover"
                        category="Here is a subtitle for this table"
                        ctTableFullWidth
                        ctTableResponsive
                        content={
                          <>
                            <button onClick={this.goToDeals}>Go Back</button>
                            <div className="header">
                              <h4 className="title">Deals Details</h4>
                            </div>
                            <button
                              onClick={this.onNewDealDetail}
                              style={{ float: "right" }}
                            >
                              New Deal Detail
                            </button>
                            <Table striped hover>
                              <thead>
                                <tr>
                                  <th>Status</th>
                                  <th>Created On</th>
                                  <th>Categories</th>
                                  <th>Measure</th>
                                  <th>Max Price</th>
                                  <th>Food_type</th>
                                  <th>Menu_type</th>
                                  <th>Title</th>
                                  <th>No_of_items</th>
                                </tr>
                              </thead>
                              <tbody>
                                {dealDetails === null || dealDetails === [] ? (
                                  <tr>
                                    <td>No data </td>
                                  </tr>
                                ) : (
                                  dealDetails.map((p, index) => {
                                    return (
                                      <tr key={index}>
                                        <td>
                                          {p.status}
                                          {/* <Toggle
                                            defaultChecked={
                                              p.status === "ACTIVE"
                                                ? true
                                                : false
                                            }
                                            // onChange={this.onChangeClick}
                                          /> */}
                                        </td>
                                        <td>{p.created_date}</td>
                                        <td>{p.categories}</td>
                                        <td>{p.measure}</td>
                                        <td>₹{p.max_price}</td>
                                        <td>{p.food_type}</td>
                                        <td>{p.menu_type}</td>
                                        <td>{p.title}</td>
                                        <td>{p.no_of_items}</td>
                                      </tr>
                                    );
                                  })
                                )}
                              </tbody>
                            </Table>
                          </>
                        }
                      />
                    </Col>
                  </Row>
                </Grid>
              </div>
            ) : (
              <>
                <div className="col-lg-12">
                  <div className="card">
                    <div className="card-close">
                      <div className="dropdown">
                        <button
                          type="button"
                          id="closeCard5"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                          className="dropdown-toggle"
                        >
                          <i className="fa fa-ellipsis-v"></i>
                          <span onClick={this.goBackToDetails}>Go Back</span>
                        </button>
                        <div
                          aria-labelledby="closeCard5"
                          className="dropdown-menu dropdown-menu-right has-shadow"
                        >
                          <a href="#profile" className="dropdown-item remove">
                            <i className="fa fa-times"></i>Close
                          </a>
                          <a href="#profile" className="dropdown-item edit">
                            <i className="fa fa-gear"></i>Edit
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="card-header d-flex align-items-center">
                      <h3 className="h4">New Deal Details</h3>
                    </div>
                    <div className="card-body">
                      <form
                        className="form-horizontal"
                        ref={(el) => (this.myFormRef2 = el)}
                      >
                        <div className="form-group row">
                          <label className="col-sm-3 form-control-label">
                            Title
                          </label>
                          <div className="col-sm-9">
                            <div className="row">
                              <div className="col-md-4">
                                <input
                                  type="text"
                                  placeholder=".col-md-4"
                                  className="form-control form-control-lg"
                                  onChange={this.onFieldChange}
                                  name="title"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="line"> </div>
                        <div className="form-group row">
                          <label className="col-sm-3 form-control-label">
                            Categories
                          </label>
                          <div className="col-sm-6">
                            <button
                              onClick={this.onCat}
                              // className="btn btn-primary"
                            >
                              Select Cats
                            </button>
                            <span> </span>
                            {this.state.catsSelected !== []
                              ? this.state.catsSelected.map((p, index) => (
                                  <span key={index}>
                                    {p.name}, <span> </span>
                                  </span>
                                ))
                              : null}
                          </div>
                        </div>
                        <div className="form-group row">
                          <label className="col-sm-3 form-control-label">
                            Items
                          </label>
                          <div className="col-sm-6">
                            <button
                              onClick={this.onItem}
                              // className="btn btn-primary"
                            >
                              Select Items
                            </button>
                            <span> </span>
                            {this.state.itemsSelected !== []
                              ? this.state.itemsSelected.map((p, index) => (
                                  <span key={index}>
                                    {p.info.name}, <span> </span>
                                  </span>
                                ))
                              : null}
                          </div>
                        </div>
                        <div className="line"></div>
                        <div className="form-group row">
                          <label className="col-sm-3 form-control-label">
                            {/* Checkboxes and radios <br /> */}
                            <small className="text-primary">Food Type</small>
                          </label>
                          <div className="col-sm-9">
                            <input
                              id="veg"
                              type="radio"
                              value="veg"
                              name="food"
                              onChange={this.onFoodChange}
                            />
                            <label htmlFor="veg">Veg</label>
                            <span> </span>
                            <input
                              id="nonveg"
                              type="radio"
                              value="nonveg"
                              name="food"
                              onChange={this.onFoodChange}
                            />
                            <label htmlFor="nonveg">Non-Veg</label>
                            <span> </span>
                            <input
                              id="any"
                              type="radio"
                              value="any"
                              name="food"
                              onChange={this.onFoodChange}
                            />
                            <label htmlFor="any">Any</label>
                          </div>
                        </div>
                        <div className="line"></div>
                        <div className="form-group row">
                          <label className="col-sm-3 form-control-label">
                            {/* Checkboxes and radios <br /> */}
                            <small className="text-primary">Menu Type</small>
                          </label>
                          <div className="col-sm-9">
                            <div>
                              <input
                                id="classic"
                                type="radio"
                                value="classic"
                                name="menu"
                                onChange={this.onMenuChange}
                              />
                              <label htmlFor="optionsRadios">Classic</label>
                              <span> </span>
                              <input
                                id="italian"
                                type="radio"
                                value="italian"
                                name="menu"
                                onChange={this.onMenuChange}
                              />
                              <label htmlFor="italian">Italian</label>
                              <span> </span>
                              <input
                                id="desi"
                                type="radio"
                                value="desi"
                                name="menu"
                                onChange={this.onMenuChange}
                              />
                              <label htmlFor="desi">Desi</label>
                            </div>
                          </div>
                        </div>
                        <div className="line"></div>
                        <div className="form-group row">
                          <label className="col-sm-3 form-control-label">
                            <small className="text-primary">Measure</small>
                          </label>
                          <div className="col-sm-9">
                            <div>
                              <input
                                id="regular"
                                type="radio"
                                value="regular"
                                name="measure"
                                onChange={this.onMeasureChange}
                              />
                              <label htmlFor="regular">Regular</label>
                              <span> </span>
                              <input
                                id="medium"
                                type="radio"
                                value="medium"
                                name="measure"
                                onChange={this.onMeasureChange}
                              />
                              <label htmlFor="medium">Medium</label>
                            </div>
                          </div>
                        </div>
                        <div className="line"></div>
                        <div className="form-group row">
                          <label className="col-sm-3 form-control-label">
                            Max Price
                          </label>
                          <div className="col-sm-9">
                            <div className="row">
                              <div className="col-md-4">
                                <input
                                  type="text"
                                  placeholder=".col-md-4"
                                  className="form-control form-control-lg"
                                  onChange={this.onFieldChange}
                                  name="maxPrice"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="line"> </div>
                        <div className="form-group row">
                          <label className="col-sm-3 form-control-label">
                            Extra Price
                          </label>
                          <div className="col-sm-9">
                            <div className="row">
                              <div className="col-md-4">
                                <input
                                  type="text"
                                  placeholder=".col-md-4"
                                  className="form-control form-control-lg"
                                  onChange={this.onFieldChange}
                                  name="extraPrice"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="line"> </div>
                        {/* <div className="form-group row">
                          <label className="col-sm-3 form-control-label">
                            Status
                          </label>
                          <div className="col-sm-9">
                            <div className="row">
                              <div className="col-md-4">
                                <Toggle
                                // defaultChecked={
                                //   p.status === "ACTIVE" ? true : false
                                // }
                                // onChange={() => this.onChangeDealStatus(p.id)}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="line"> </div> */}
                        <div className="form-group row">
                          <div className="col-sm-4 offset-sm-3">
                            <button type="submit" className="btn btn-secondary">
                              Cancel
                            </button>
                            <span> </span>
                            <button
                              // type="submit"
                              className="btn btn-primary"
                              onClick={this.onDealDetail}
                            >
                              Save changes
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>

                  <div className="content">
                    {categories && (
                      <Modal
                        isOpen={true}
                        onRequestClose={this.closeModal}
                        ariaHideApp={false}
                        className="Modal"
                        // scrollable={true}
                      >
                        <Zoom scrollable={true}>
                          <Card
                            content={
                              <div className="table-full-width">
                                <table className="table">
                                  <tbody>
                                    {categories
                                      ? categories.map((p, index) => (
                                          <tr key={index}>
                                            {/* <tr> */}
                                            <td>
                                              <div className="checkbox checkbox-inline">
                                                <input
                                                  id={p.id}
                                                  type="checkbox"
                                                  onChange={() =>
                                                    this.handleAddedCat(p)
                                                  }
                                                />
                                                <label htmlFor={p.id}>
                                                  {p.name}
                                                </label>
                                              </div>
                                            </td>
                                          </tr>
                                        ))
                                      : null}
                                  </tbody>
                                </table>
                                <table className="table">
                                  <tbody>
                                    <tr></tr>
                                  </tbody>
                                </table>
                                <span> </span>
                                <span> </span>
                                {/* <Button
                      bsStyle="info"
                      pullright="true"
                      fill="true"
                      type="submit"
                      onClick={this.setAddedCats}
                    >
                      Add cats
                    </Button> */}
                              </div>
                            }
                          />
                        </Zoom>
                      </Modal>
                    )}
                  </div>
                  <div className="content">
                    {items && (
                      <Modal
                        isOpen={true}
                        onRequestClose={this.closeModal}
                        ariaHideApp={false}
                        className="Modal"
                        // scrollable={true}
                      >
                        <Zoom scrollable={true}>
                          <Card
                            content={
                              <div className="table-full-width">
                                <table className="table">
                                  <thead>
                                    <tr>
                                      {this.state.catsSelected.map(
                                        (p, index) => (
                                          <th
                                            key={index}
                                            onClick={() => this.getItems(p.id)}
                                          >
                                            {p.name}
                                          </th>
                                        )
                                      )}
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {this.state.getItems
                                      ? this.state.getItems.map((p, index) => (
                                          <tr key={index}>
                                            <td>
                                              <div className="checkbox checkbox-inline">
                                                <input
                                                  id={p.info.id}
                                                  type="checkbox"
                                                  onChange={() =>
                                                    this.handleAddedItem(p)
                                                  }
                                                />
                                                <label htmlFor={p.info.id}>
                                                  {p.info.name}
                                                </label>
                                              </div>
                                            </td>
                                          </tr>
                                        ))
                                      : null}
                                  </tbody>
                                </table>
                                <table className="table">
                                  <tbody>
                                    <tr></tr>
                                  </tbody>
                                </table>
                                <span> </span>
                                <span> </span>
                              </div>
                            }
                          />
                        </Zoom>
                      </Modal>
                    )}
                  </div>
                </div>
              </>
            )}
          </>
        )}
      </div>
    );
  }
  async componentDidMount() {
    this.getDate();
    this.getDeals();
  }
}
export default Deals;
