import React from "react";
// import Poster from "../Poster";
// import Chart from "./Chart";
import Chart1 from "./Chart1";
import Chart2 from "./Chart2";
import { CURRENCY } from "../../Network/ApiPaths";
// import Chart3 from "./Chart3";
// import Chart4 from "./Chart4";
// import SuperAdminCards from "../SuperAdminCards";
// import { Grid, Row, Col } from "react-bootstrap";
// import Card from "../Card/Card";
const SuperAdminRootComponent = (props) => (
  <div>
    <header className="page-header">
      <div className="container-fluid">
        <h2 className="no-margin-bottom">Welcome To H {"&"} B MART</h2>
        {/* <h2 className="no-margin-bottom">
          <a
            href="http://crazypizza.treosoftit.com/"
            rel="noopener noreferrer"
            target="_blank"
          >
            POS Take Order
          </a>
        </h2> */}
      </div>
    </header>

    {/* <section className="dashboard-header">
      <div className="container-fluid">
        <div className="row">
          {props.stores !== []
            ? props.stores.map((p, index) => (
                <div className="statistics col-lg-4 col-4" key={index} */}

    {/* <section className="dashboard-header">
      <div className="container-fluid">
        <div className="row">
          {props.stores !== []
            ? props.stores.map((p, index) => (
                <div className="chart col-lg-4 col-12">
                  <div className="statistic d-flex align-items-center bg-white has-shadow">
                    <div className="icon bg-red">
                      <i className="fa fa-tasks"></i>
                    </div>
                    <div className="text">
                      <strong>zsfgdxdfbdgf</strong>
                      <br />
                      <br />
                                            <h2 style={{ color: "black" }}>
Orders: 234</h2>
                                            <h2 style={{ color: "black" }}>
Orders: 234</h2>
                                            <h2 style={{ color: "black" }}>
Orders: 234</h2>
                    </div>
                  </div>
                  <br />
                </div>
              ))
            : null}
        </div>
      </div>
    </section> */}
    <section className="dashboard-header">
      <div className="container-fluid">
        <div className="row">
          {props.misc !== []
            ? props.misc.map((p, index) => (
                <div className="chart col-lg-4 col-12" key={index}>
                  <div
                    className={
                      props.storeId === p.store.id
                        ? "statistic d-flex align-items-center bg-blue has-shadow"
                        : "statistic d-flex align-items-center bg-white has-shadow"
                    }
                    onClick={() => props.selectedStoreId(p)}
                  >
                    <div className="icon bg-red">
                      <i className="fa fa-tasks"></i>
                    </div>
                    <div className="text">
                      <strong>{p.store.name}</strong>
                      <br />
                      <br />
                      <h2 style={{ color: "black" }}>
                        Orders&nbsp;&nbsp;&nbsp;
                        {p.report.OrdersCount}
                        {/* {props.misc
                          ? props.misc !== []
                            ? props.misc
                                .filter(
                                  (q) =>
                                    q.range === "Today" &&
                                    q.store_id === p.store_id
                                )
                                .map((p, index) => (
                                  <span key={index} style={{ float: "right" }}>
                                    {p.OrdersCount}
                                  </span>
                                ))
                            : null
                          : null} */}
                      </h2>
                      <h2 style={{ color: "black" }}>
                        Gross&nbsp;&nbsp;&nbsp;
                        <span
                          key={index}
                          style={{ float: "right" }}
                          // className="fa fa-inr"
                        >
                          <i className={CURRENCY} aria-hidden="true"></i>
                          {Math.round(p.report.final_amount)}
                        </span>
                        {/* {props.misc
                          ? props.misc !== []
                            ? props.misc
                                .filter(
                                  (q) =>
                                    q.range === "Today" &&
                                    q.store_id === p.store_id
                                )
                                .map((p, index) => (
                                  <span
                                    key={index}
                                    style={{ float: "right" }}
                                    // className="fa fa-inr"
                                  >
                                    {" "}
                                    <i
                                      className={CURRENCY}
                                      aria-hidden="true"
                                    ></i>
                                    {Math.round(p.final_amount)}
                                  </span>
                                ))
                            : null
                          : null} */}
                      </h2>
                      <h2 style={{ color: "black" }}>
                        Net&nbsp;&nbsp;&nbsp;
                        <span key={index} style={{ float: "right" }}>
                          <i className={CURRENCY} aria-hidden="true"></i>
                          {Math.round(p.report.total)}
                        </span>
                        {/* {props.misc
                          ? props.misc !== []
                            ? props.misc
                                .filter(
                                  (q) =>
                                    q.range === "Today" &&
                                    q.store_id === p.store_id
                                )
                                .map((p, index) => (
                                  <span key={index} style={{ float: "right" }}>
                                    {" "}
                                    <i
                                      className={CURRENCY}
                                      aria-hidden="true"
                                    ></i>
                                    {Math.round(p.total)}
                                  </span>
                                ))
                            : null
                          : null} */}
                      </h2>
                    </div>
                  </div>
                  <br />
                </div>
              ))
            : null}
        </div>
      </div>
    </section>
    {/* ////////////////////////////////////////////////////////////////////////////thisis right */}
    {/* <section className="dashboard-header">
      <div className="container-fluid">
        <div className="row">
          {props.stores !== []
            ? props.stores.map((p, index) => (
                <div className="chart col-lg-4 col-12" key={index}>
                  <div
                    className={
                      props.storeId === p.id
                        ? "statistic d-flex align-items-center bg-blue has-shadow"
                        : "statistic d-flex align-items-center bg-white has-shadow"
                    }
                    onClick={() => props.selectedStoreId(p.id)}
                    
                  >
                    {/* <div className="icon bg-red">
                      <i className="fa fa-tasks"></i>
                    </div> */}
    {/* style={{ fontSize: "15px" }}
                    <div className="text">
                    
                      <strong>{p.storeName}</strong>
                      <br />
                      <br />
                      <h2 style={{ color: "black" }}>
                        Orders&nbsp;&nbsp;&nbsp;
                        {props.misc
                          ? props.misc !== []
                            ? props.misc
                                .filter(
                                  (q) =>
                                    q.range === "Today" &&
                                    q.store_id === p.store_id
                                )
                                .map((p, index) => (
                                  <span key={index} style={{ float: "right" }}>
                                    {p.OrdersCount}
                                  </span>
                                ))
                            : null
                          : null}
                      </h2>
                      <h2 style={{ color: "black" }}>
                        Gross&nbsp;&nbsp;&nbsp;
                        {props.misc
                          ? props.misc !== []
                            ? props.misc
                                .filter(
                                  (q) =>
                                    q.range === "Today" &&
                                    q.store_id === p.store_id
                                )
                                .map((p, index) => (
                                  <span
                                    key={index}
                                    style={{ float: "right" }}
                                    // className="fa fa-inr"
                                  >
                                    {" "}
                                    <i
                                      className={CURRENCY}
                                      aria-hidden="true"
                                    ></i>
                                    {Math.round(p.final_amount)}
                                  </span>
                                ))
                            : null
                          : null}
                      </h2>
                      <h2 style={{ color: "black" }}>
                        Net&nbsp;&nbsp;&nbsp;
                        {props.misc
                          ? props.misc !== []
                            ? props.misc
                                .filter(
                                  (q) =>
                                    q.range === "Today" &&
                                    q.store_id === p.store_id
                                )
                                .map((p, index) => (
                                  <span key={index} style={{ float: "right" }}>
                                    {" "}
                                    <i
                                      className={CURRENCY}
                                      aria-hidden="true"
                                    ></i>
                                    {Math.round(p.total)}
                                  </span>
                                ))
                            : null
                          : null}
                      </h2>
                    </div>
                    
                  </div>
                  <br />
                </div>
              ))
            : null}
        </div>
      </div>
    </section> */}
    {/* till now not after this //////////////////////////////////////////////////////////////////////////// */}
    <section className="feeds no-padding-top">
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12">
            <div className="line-chart-example card">
              <div className="card-header d-flex align-items-center">
                <h3 className="h4">Today Orders</h3>
              </div>
              <div className="card-body">
                <Chart2 chartData={props.misc} />
              </div>
            </div>
          </div>
          <div className="col-lg-12">
            <div className="line-chart-example card">
              <div className="card-header d-flex align-items-center">
                <h3 className="h4">Today Sale</h3>
              </div>
              <div className="card-body">
                <Chart1 chartData={props.misc} />
              </div>
            </div>
          </div>
          {/* <div className="col-lg-6">
            <div className="line-chart-example card">
              <div className="card-header d-flex align-items-center">
                <h3 className="h4">Today's Gross Sales</h3>
              </div>
              <div className="card-body">
                <Chart4 chartData={props.misc} />
              </div>
            </div>
          </div> */}
          {/* <div className="col-lg-7">
            <div className="line-chart-example card">
              <div className="card-header d-flex align-items-center">
                <h3 className="h4">Todays Net Sales</h3>
              </div>
              <div className="card-body">
                <Chart3 chartData={props.misc} />
              </div>
            </div>
          </div>
          <div className="col-lg-5">
            <div className="daily-feeds card">
              <div className="card-header">
                <h3 className="h4">Daily Feeds</h3>
              </div>
              <div className="card-body no-padding">
                <div className="item">
                  <div className="feed d-flex justify-content-between">
                    <div className="feed-body d-flex justify-content-between">
                      <div className="content">
                        <h5>COD</h5>
                        <br />
                        <h5>PayTM</h5>
                        <br />
                        <h5>CARD</h5>
                        <br />
                        <h5>Swipe</h5>
                        <br />
                        <h5>COD</h5>
                        <br />
                        <h5>PayTM</h5>
                        <br />
                        <h5>CARD</h5>
                      </div>
                    </div>
                    <div className="date text-right">
                      <h5>COD</h5>
                      <br />
                      <h5>PayTM</h5>
                      <br />
                      <h5>CARD</h5>
                      <br />
                      <h5>Swipe</h5>
                      <br />
                      <h5>COD</h5>
                      <br />
                      <h5>PayTM</h5>
                      <br />
                      <h5>CARD</h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
          {/* <div className="col-lg-6">
            <div className="line-chart-example card">
              <div className="card-header d-flex align-items-center">
                <h3 className="h4">Donut Net Sales</h3>
              </div>
              <div className="card-body">
                <Chart3 chartData={props.misc} />
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </section>
  </div>
);

export default SuperAdminRootComponent;
