import React from "react";
import Toggle from "react-toggle";
class PincodesComponent extends React.Component {
  constructor(props) {
    super();
  }
  render() {
    return (
      <section className="feeds no-padding-top">
        <div className="container-fluid">
          <div className="row">
            <div className="card-body text-center">
              <div
                id="myModal"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
                className="modal fade text-left"
              >
                <div role="document" className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h4 id="exampleModalLabel" className="modal-title">
                        Add Pin Code
                      </h4>
                      <button
                        type="button"
                        data-dismiss="modal"
                        aria-label="Close"
                        className="close"
                      >
                        <span aria-hidden="true">×</span>
                      </button>
                    </div>
                    <div className="modal-body">
                      <form
                        ref={(el) =>
                          this.props.reset
                            ? this.myFormRef.reset()
                            : (this.myFormRef = el)
                        }
                      >
                        <div className="form-group">
                          <label>Name</label>
                          <input
                            onChange={this.props.nameChange}
                            type="email"
                            placeholder="pincode"
                            className="form-control"
                          />
                        </div>
                        <div className="form-group row">
                          <div className="col-sm-9">
                            <div>
                              <input
                                id="option"
                                type="checkbox"
                                value=""
                                defaultChecked
                              />
                              <label htmlFor="option"> Active</label>
                            </div>
                          </div>
                        </div>
                        <div
                          className="form-group"
                          onClick={this.props.add}
                          data-dismiss="modal"
                        >
                          <input
                            type="submit"
                            value="ADD"
                            className="btn btn-primary"
                          />
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card-body text-center">
              <div
                id="myModal2"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
                className="modal fade text-left"
              >
                <div role="document" className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h4 id="exampleModalLabel" className="modal-title">
                        Edit Pin Code
                      </h4>
                      <button
                        type="button"
                        data-dismiss="modal"
                        aria-label="Close"
                        className="close"
                      >
                        <span aria-hidden="true">×</span>
                      </button>
                    </div>
                    <div className="modal-body">
                      <form>
                        <div className="form-group">
                          <label>Name</label>
                          <input
                            onChange={this.props.onNameUpdate}
                            type="email"
                            placeholder="pincode"
                            className="form-control"
                          />
                          <span>
                            &nbsp;
                            {this.props.selectedPin
                              ? this.props.selectedPin.name
                              : null}
                          </span>
                          <br />
                          <label>D Date</label>
                          <input
                            onChange={this.props.onDateUpdate}
                            type="date"
                            placeholder="date"
                            className="form-control"
                          />
                          <span>
                            &nbsp;
                            {this.props.selectedPin
                              ? this.props.selectedPin.delivary_date
                              : null}
                          </span>
                        </div>
                        <div className="form-group row">
                          <div className="col-sm-9">
                            <div>
                              <input
                                id="option"
                                type="checkbox"
                                value=""
                                defaultChecked
                              />
                              <label htmlFor="option"> Active</label>
                            </div>
                          </div>
                        </div>
                        <div
                          className="form-group"
                          onClick={this.props.update}
                          data-dismiss="modal"
                        >
                          <input
                            type="submit"
                            value="UPDATE"
                            className="btn btn-primary"
                          />
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card-body text-center">
              <div
                id="myModal3"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
                className="modal fade text-left"
              >
                <div role="document" className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h4 id="exampleModalLabel" className="modal-title">
                        Do you Want to Delete This Pin Code
                      </h4>
                      <button
                        type="button"
                        data-dismiss="modal"
                        aria-label="Close"
                        className="close"
                      >
                        <span aria-hidden="true">x</span>
                      </button>
                    </div>
                    <div className="modal-footer">
                      <button
                        type="button"
                        className="btn btn-primary"
                        data-dismiss="modal"
                        onClick={this.props.deletePin}
                      >
                        Yes
                      </button>
                      <button
                        type="button"
                        data-dismiss="modal"
                        className="btn btn-secondary"
                      >
                        No
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-12">
              <div className="checklist card">
                <div className="card-close">
                  <div className="dropdown">
                    <button
                      type="button"
                      data-toggle="modal"
                      data-target="#myModal"
                      className="btn btn-primary"
                    >
                      Add Pin Code
                    </button>
                    <span>
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span>
                  </div>
                </div>
                {/* <div className="card-close">
                  <div className="dropdown">
                    <button
                      type="button"
                      id="closeCard5"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                      className="dropdown-toggle"
                    >
                      <i className="fa fa-ellipsis-v"></i>
                    </button>
                    <div
                      aria-labelledby="closeCard5"
                      className="dropdown-menu dropdown-menu-right has-shadow"
                    >
                      <a href="#profile" className="dropdown-item remove">
                        <i className="fa fa-times"></i>Close
                      </a>
                      <a href="#profile" className="dropdown-item edit">
                        <i className="fa fa-gear"></i>Edit
                      </a>
                    </div>
                  </div>
                </div> */}
                <div className="card-header d-flex align-items-center">
                  <h2 className="h3">PIN Codes</h2>
                </div>
                {/* <div className="card-body no-padding">
              {props.data.map((p, index) => (
                <div key={index} className="item d-flex">
                  <label htmlFor="input-1">{p.name}</label>
                  <span>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </span>
                  <span>{p.status}</span>
                  <span>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </span>
                  <span>{p.delivary_date}</span>
                </div>
              ))}
            </div> */}
                <div className="card-body">
                  <div className="table-responsive">
                    <table className="table">
                      <thead>
                        <tr>
                          <th>S.No</th>
                          <th>Name</th>
                          <th>Status</th>
                          <th>Delivary Date</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.props.data
                          ? this.props.data.map((p, index) => (
                              <tr key={index}>
                                <th scope="row">{index + 1}</th>
                                <td>{p.name}</td>
                                {/* <td>{p.status}</td> */}
                                <td>
                                  <Toggle
                                    defaultChecked={
                                      p.status === "ACTIVE" ? true : false
                                    }
                                    onChange={() =>
                                      this.props.onChangePinStatus(p)
                                    }
                                  />
                                </td>

                                <td>{p.delivary_date}</td>
                                <td
                                  onClick={() => this.props.initiatedEditing(p)}
                                  data-toggle="modal"
                                  data-target="#myModal2"
                                  className="fa fa-edit fa-lg"
                                ></td>
                                {/* <td
                                  onClick={() =>
                                    this.props.onPinTriggerDelete(p.id)
                                  }
                                  data-toggle="modal"
                                  data-target="#myModal3"
                                  className="fa fa-trash-o fa-lg"
                                ></td> */}
                              </tr>
                            ))
                          : null}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default PincodesComponent;
