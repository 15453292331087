import React, { Component } from "react";
import { Grid, Row, Col, Table } from "react-bootstrap";
import Card from "../Card/Card";
import { Button } from "react-bootstrap";
import { Image } from "react-bootstrap";
import Modal from "react-modal";
import Zoom from "react-reveal/Zoom";
import ReactToPrint from "react-to-print";
// import postClientDataResponse from "../../Network/postClientDataResponse";
import imgsrc from "../../images/cart-add-icon.png";
import deletecartIcon from "../../images/delete-cart1.png";
import deletecartAll from "../../images/delete.png";
import refreshImg from "../../images/refresh.png";
import orderImg from "../../images/check.png";
import printerImg from "../../images/printer.png";
import {
  // CATEGORY_LIST,
  ACTIVE_DEALS_LIST,
  DEAL_DETAILS,
  DEALS_DETAILS_LIST,
  IMG_PATH_CATEGORY,
  IMG_PATH_PRODUCT,
  // MENU_LIST,
  MENU_LIST,
  // HOST,
  DELETE_CART,
  ADD_TO_CART,
  REMOVE_FROM_CART,
  PLACE_ORDER,
  // STORE_LIST,
} from "../../Network/ApiPaths";
import Uri from "../../Network/Uri";
import postClientDataResponse from "../../Network/PostClientDataResponse";
import getResponseClient from "../../Network/GetClientDataResponse";
let clientHost;
let storeFresh;
// let storeCode;
let storeName;
let gst;
// let pan;
let storeUserId;
let storeIdValue;
let longitude;
let latitude;
let storeAddress;
let storeDet;
class TakeOrder extends Component {
  constructor(props) {
    super();
    const userCheck = window.localStorage.getItem("access");
    storeDet = JSON.parse(userCheck);
    if (storeDet !== null) {
      storeIdValue = storeDet.storeId;
      storeUserId = storeDet.userId;
      longitude = storeDet.longitude;
      latitude = storeDet.latitute;
      // storeName = storeDet.storeName;
      storeAddress = storeDet.address;
      gst = storeDet.gst[0];
    } else {
      storeIdValue = "";
      storeUserId = "";
      longitude = "";
      latitude = "";
      // storeName = "";
      storeAddress = "";
      gst = "";
    }
    const client = window.localStorage.getItem("clientAccess");
    storeFresh = JSON.parse(client);
    if (storeFresh !== null) {
      storeIdValue = storeFresh.storeId;
      clientHost = JSON.parse(window.localStorage.getItem("clientAccess")).host;
      // storeCode = JSON.parse(window.localStorage.getItem("clientAccess"))
      //   .storeCode;
      storeName = JSON.parse(window.localStorage.getItem("clientAccess"))
        .storeName;
    }
    this.state = {
      freeOne: "",
      freeTwo: "",
      dlsSelected: false,
      catSelected: false,
      proSelected: false,
      product: null,
      customer: null,
      radio: "",
      measure: "",
      check: [],
      deals: [],
      categories: [],
      products: [],
      filteredProducts: [],
      selectedCatProducts: [],
      priceID: "",
      cart: [],
      addons: [],
      selectedAddons: [],
      itemNumber: "1",
      cartPrice: {},
      pay: "",
      orderPlaced: false,
      date: "",
      finalId: "",
      orderedItems: [],
      mobile: "",
      amount: "",
      change: "",
      customer_phone: "",
      cash_received: "",
      cash_balance: "",
      search: true,
      searchValue: "",
    };
  }
  searchInitiate = (iName) => {
    this.setState({
      // filteredProducts: [],
      searchValue: iName,
    });
    this.onSearch(iName);
  };

  onSearch = (itemName) => {
    // if (this.state.filteredProducts === []) {
    //   console.log("filtered empty");
    // } else {
    //   console.log("filtered not empty");
    // }
    let allProducts = this.state.products;
    const filteredData = allProducts.filter((product) => {
      let info = product.info;
      return (
        info.name
          .toLowerCase()
          // === itemName
          .includes(itemName.toLowerCase())
      );

      // .includes(itemName);
    });
    if (itemName === "" || (null && this.state.catId !== "")) {
      this.setState({
        // products:
        filteredProducts: this.state.selectedCatProducts,
      });
    } else {
      this.setState({
        // products:
        filteredProducts: filteredData,
      });
    }
  };
  setdDetails = (d) => {
    let dd = d.map((p) => p.items);
    var apiPath = new Uri(clientHost + DEALS_DETAILS_LIST).addQueryParam(
      "ids",
      dd
    );
    // .addQueryParam("storeid", storeIdValue);
    getResponseClient(
      apiPath,
      storeFresh.key,
      (onSuccessResponse) => {
        this.setState({
          dealItemsDetails: onSuccessResponse.result,
        });
      },
      (onError) => {
        // alert(onError.message);
      }
    );
  };
  onDls = (e) => {
    this.setState({
      dealItemsDetails: [],
    });
    var apiPath1 = new Uri(clientHost + DEAL_DETAILS)
      .addQueryParam("dealId", e.id)
      .addQueryParam("storeid", storeIdValue);
    getResponseClient(
      apiPath1,
      storeFresh.key,
      (onSuccessResponse) => {
        // this.setdDetails(onSuccessResponse.result);
        let freeItems = this.state.deals.filter((q) => q.id === e.id);
        let freeOne = freeItems.map((q) => q.complementary_pid_one);
        let freeTwo = freeItems.map((q) => q.complementary_pid_two);
        this.setState({
          // dealDetails: e,
          dlsSelected: true,
          dealItems: onSuccessResponse.result,
          freeOne: freeOne,
          freeTwo: freeTwo,
        });
      },
      (onError) => {
        // alert(onError.message);
      }
    );

    //   const products = this.state.products
    //     ? this.state.products !== [] || this.state.products.null
    //       ? this.state.products.filter((q) => q.info.category_id === e.id)
    //       : null
    //     : null;

    //   this.setState({
    //     selectedCatProducts: products,
    //     filteredProducts: products,
    //     catSelected: true,
    //     catId: e.id,
    //     addonStatus: e.addon_status,
    //     search: true,
    //     searchValue: "",
    //   });
  };
  // onCat = (e) => {
  //   // this.setState({
  //   //   catSelected: true,
  //   //   catId: e.id,
  //   //   addonStatus: e.addon_status,
  //   // });

  //   const products = this.state.products
  //     ? this.state.products !== [] || this.state.products.null
  //       ? this.state.products.filter((q) => q.info.category_id === e.id)
  //       : null
  //     : null;

  //   this.setState({
  //     selectedCatProducts: products,
  //     filteredProducts: products,
  //     catSelected: true,
  //     catId: e.id,
  //     addonStatus: e.addon_status,
  //     search: true,
  //     searchValue: "",
  //   });
  // };
  onProduct = (p) => {
    if (p.prices) {
      if (p.prices.length > 1) {
        if (this.state.priceID !== "") {
          this.setState({ product: p });
        } else {
          // alert("select price");
        }
      } else {
        this.setState({ product: p });
      }
    }
  };
  openModal = (product) => {
    this.setState({ product });
  };
  closeModal = () => {
    this.setState({ product: null });
  };
  onRadioChanged = (e) => {
    this.setState({
      priceID: e.id,
      measure: e.measure,
    });
  };
  onPaytype = (e) => {
    this.setState({ pay: e.target.value });
  };
  checkChange = (e) => {
    this.setState({ check: e.target.name });
  };
  addToCart = (p) => {
    this.setState({
      proSelected: true,
      product: null,
      orderPlaced: false,
      finalId: "",
    });
    var apiPath1 = new Uri(clientHost + DEAL_DETAILS)
      .addQueryParam("dealId", p.deal_id)
      .addQueryParam("storeid", storeIdValue);
    getResponseClient(
      apiPath1,
      storeFresh.key,
      (onSuccessResponse) => {
        this.addDealTocart(onSuccessResponse.result);
        // this.addDealItemsTocart(onSuccessResponse.result);
      },
      (onError) => {
        // alert(onError.message);
      }
    );
  };
  addDealTocart = (d) => {
    let ddd = d.map((dmd) => dmd);

    console.log(d.map((dmd) => dmd.deal_id));
    console.log(ddd.deal_id);
    console.log(ddd.deal_id.toString());
    const requestBody = {
      deal_id: d.map((dmd) => dmd.deal_id),
      items: d.map((dmd) => dmd.items),
      type: d.map((dmd) => dmd.type),
      complementary_items: d.map((dmd) => dmd.complementary_items),
      quantity: this.state.itemNumber,
      user_id: storeUserId,
    };
    postClientDataResponse(
      clientHost + ADD_TO_CART,
      storeFresh.key,
      requestBody,
      (onSuccessResponse) => {
        this.setState({
          selectedAddons: [],
          itemNumber: "1",
          priceID: "",
          measure: "",
        });
        this.getCart();
      },
      (onError) => {
        // alert(onError);
      }
    );
  };
  getCart = () => {
    // let dd = this.state.dealItems.map((p) => p.items);
    // var apiPath = new Uri(clientHost + DEALS_DETAILS_LIST).addQueryParam(
    //   "ids",
    //   dd
    // );
    // // .addQueryParam("storeid", storeIdValue);
    // getResponseClient(
    //   apiPath,
    //   storeFresh.key,
    //   (onSuccessResponse) => {
    //     this.setState({
    //       dealItemsDetails: onSuccessResponse.result,
    //     });
    //   },
    //   (onError) => {
    //     // alert(onError.message);
    //   }
    // );

    var apiPath = new Uri(
      clientHost +
        `Cart/userCartItems?offset=0&user_id=${storeUserId}&limit=100`
    );
    getResponseClient(
      apiPath,
      storeFresh.key,
      (onSuccessResponse) => {
        console.log(onSuccessResponse.result);
        this.setState({ cart: onSuccessResponse.result, pay: "" });
      },
      (onError) => {
        // alert(onError.message);
      }
    );
    var apiPath2 = new Uri(clientHost + `Cart/cartPrice?userid=${storeUserId}`);
    getResponseClient(
      apiPath2,
      storeFresh.key,
      (onSuccessResponse) => {
        this.setState({ cartPrice: onSuccessResponse.result });
      },
      (onError) => {
        // alert(onError.message);
      }
    );
  };
  handleAddon = (e, p) => {
    let measureCheck = this.state.measure;
    if (measureCheck !== "") {
      if (measureCheck === "Regular") {
        let y = {
          name: e.name,
          id: e.id,
          price: e.price_regular,
          quantity: this.state.itemNumber,
        };
        this.setState({ selectedAddons: [...this.state.selectedAddons, y] });
      } else {
        let y = {
          name: e.name,
          id: e.id,
          price: e.price_medium,
          quantity: this.state.itemNumber,
        };
        this.setState({ selectedAddons: [...this.state.selectedAddons, y] });
      }
    } else {
      let y = {
        name: e.name,
        id: e.id,
        price: e.price_regular,
        quantity: this.state.itemNumber,
      };
      this.setState({ selectedAddons: [...this.state.selectedAddons, y] });
    }
  };
  onOrderVerify = () => {
    this.setState({
      mobile: this.state.mobile,
      amount: this.state.amount,
    });
    let payOption = this.state.pay;
    if (payOption === "") {
      alert("select payment");
    } else if (payOption === "online") {
      // if (
      //   this.state.mobile === null ||
      //   this.state.mobile === "" ||
      //   this.state.mobile === undefined
      //   // || this.state.mobile.length !== 10 ||
      //   // typeof this.state.mobile !== Number
      // ) {
      //   alert("Phone number Error");
      // } else {
      this.placeOnlineOrder();
      // }
    } else if (payOption === "swipe") {
      // if (
      //   this.state.mobile === null ||
      //   this.state.mobile === "" ||
      //   this.state.mobile === undefined
      //   // || this.state.mobile.length !== 10 ||
      //   // typeof this.state.mobile !== Number
      // ) {
      //   alert("Phone number Error");
      // } else {
      this.placeSwipeOrder();
      // }
    } else {
      if (
        //   this.state.mobile === null ||
        //   this.state.mobile === "" ||
        //   this.state.mobile === undefined
        //   // || this.state.mobile.length !== 10 ||
        //   // typeof this.state.mobile !== Number
        // ) {
        //   alert("Phone number Error");
        // } else if (
        this.state.amount === null ||
        this.state.amount === "" ||
        this.state.amount === undefined
        // || typeof this.state.amount !== Number
      ) {
        alert("Amount error");
      } else {
        this.onPlaceOrder();
      }
    }
  };
  onFieldChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  onForward = () => {
    this.setState({
      mobile: this.state.mobile,
      amount: this.state.amount,
    });
    let payOption = this.state.pay;

    if (payOption === "online") {
      // if (
      //   this.state.mobile === null ||
      //   this.state.mobile === "" ||
      //   this.state.mobile === undefined
      //   // || this.state.mobile.length !== 10 ||
      //   // typeof this.state.mobile !== Number
      // ) {
      //   // alert("Phone number Error");
      // } else {
      this.setState({
        customer: "",
      });
      this.placeOnlineOrder();
      // }
    } else if (payOption === "swipe") {
      // if (
      //   this.state.mobile === null ||
      //   this.state.mobile === "" ||
      //   this.state.mobile === undefined
      //   // || this.state.mobile.length !== 10 ||
      //   // typeof this.state.mobile !== Number
      // ) {
      //   // alert("Phone number Error");
      // } else {
      this.setState({
        customer: "",
      });
      this.placeSwipeOrder();
      // }
    } else {
      this.validate();
    }
  };
  validate = () => {
    // let phoneStatus = parseInt(this.state.mobile);
    // if (
    //   this.state.mobile === null ||
    //   this.state.mobile === "" ||
    //   this.state.mobile === undefined
    //   // || this.state.mobile.length !== 10 ||
    //   // typeof this.state.mobile !== Number
    // ) {
    //   // alert("Phone number Error");
    // } else
    if (
      this.state.amount === null ||
      this.state.amount === "" ||
      this.state.amount === undefined
      // || typeof this.state.amount !== Number
    ) {
      // alert("Amount error");
    } else {
      this.setState({
        customer: "",
      });
      // if (payOption === "online") {
      //   this.placeOnlineOrder();
      // } else if (payOption === "swipe") {
      //   this.placeSwipeOrder();
      // }else{
      this.onPlaceOrder();
      // }
    }
  };
  placeSwipeOrder = () => {
    const requestBody = {
      userId: storeUserId,
      storeId: storeIdValue,
      shipping_address: storeName,
      payment_mode: "CARD",
      payment_status: "PAID_BY_MACHINE",
      online_ref_id: "",
      platform: "POS",
      landmark: "PG",
      address_lat: latitude,
      address_lng: longitude,
      order_status: "4",
      customer_phone: this.state.mobile,
      cash_received: "",
      cash_balance: "0.0",
    };
    postClientDataResponse(
      clientHost + PLACE_ORDER,
      storeFresh.key,
      requestBody,
      (onSuccessResponse) => {
        this.setState({
          orderPlaced: true,
          finalId: onSuccessResponse.data.result,
        });
        this.getItems();
      },
      (onError) => {
        // alert(onError);
      }
    );
  };
  placeOnlineOrder = () => {
    const requestBody = {
      userId: storeUserId,
      storeId: storeIdValue,
      shipping_address: storeName,
      payment_mode: "ONLINE",
      payment_status: "PAID_BY_PAYTM",
      platform: "POS",
      online_ref_id: "online_90",
      landmark: "PG",
      address_lat: latitude,
      address_lng: longitude,
      order_status: "4",
      customer_phone: this.state.mobile,
      cash_received: "",
      cash_balance: "0.0",
      items: JSON.stringify(this.state.cart),
    };
    postClientDataResponse(
      clientHost + PLACE_ORDER,
      storeFresh.key,
      requestBody,
      (onSuccessResponse) => {
        this.setState({
          orderPlaced: true,
          finalId: onSuccessResponse.data.result,
        });
        this.getItems();
      },
      (onError) => {
        // alert(onError);
      }
    );
  };
  onPlaceOrder = () => {
    const requestBody = {
      userId: storeUserId,
      storeId: storeIdValue,
      shipping_address: storeName,
      payment_mode: "COD",
      payment_status: "PAID_BY_COD",
      paytm_ref_id: "cod_90",
      platform: "POS",
      landmark: "PG",
      address_lat: latitude,
      address_lng: longitude,
      order_status: "4",
      customer_phone: this.state.mobile,
      cash_received: this.state.amount,
      cash_balance:
        Number(this.state.amount) -
        Math.round(
          Number(this.state.cartPrice.itemsTotal) +
            Number(this.state.cartPrice.addonTotal) +
            (5 / 100) * Number(this.state.cartPrice.itemsTotal) +
            Number(this.state.cartPrice.addonTotal)
        ),
    };
    if (requestBody.cash_balance > 0) {
      postClientDataResponse(
        clientHost + PLACE_ORDER,
        storeFresh.key,
        requestBody,
        (onSuccessResponse) => {
          this.setState({
            orderPlaced: true,
            finalId: onSuccessResponse.data.result,
          });
          this.getItems();
        },
        (onError) => {
          // alert(onError);
        }
      );
    }
  };
  getItems = () => {
    var apiPath = new Uri(
      clientHost + `Order/getOrderItems?order_id=${this.state.finalId}`
    );
    getResponseClient(
      apiPath,
      storeFresh.key,
      (onSuccessResponse) => {
        this.setState({
          orderedItems: onSuccessResponse.result,
        });
      },
      (onError) => {
        // alert(onError.message);
      }
    );
  };
  deleteCart = () => {
    let userid = storeUserId;
    var apiPath = new Uri(clientHost + DELETE_CART).addQueryParam(
      "userid",
      userid
    );
    getResponseClient(
      apiPath,
      storeFresh.key,
      (onSuccessResponse) => {
        this.getCart();
      },
      (onError) => {
        // alert(onError);
      }
    );
  };
  removeFromCart = (e) => {
    let removeId = e.id;
    var apiPath = new Uri(clientHost + REMOVE_FROM_CART).addQueryParam(
      "id",
      removeId
    );
    getResponseClient(
      apiPath,
      storeFresh.key,
      (onSuccessResponse) => {
        this.getCart();
      },
      (onError) => {
        // alert(onError);
      }
    );
  };
  checkAddons = (p) => {
    let x = this.state.selectedAddons;
    if (x.length === 0) {
      // alert("add addons");
    } else {
      this.addWithAddons(p);
    }
  };
  addWithAddons = (p) => {
    this.setState({
      proSelected: true,
      product: null,
    });
    if (p.prices) {
      if (p.prices.length > 1) {
        const requestBody = {
          quantity: this.state.itemNumber,
          price_id: this.state.priceID,
          user_id: storeUserId,
          product_id: p.info.id,
          addons: JSON.stringify(this.state.selectedAddons),
        };
        postClientDataResponse(
          clientHost + ADD_TO_CART,
          storeFresh.key,
          requestBody,
          (onSuccessResponse) => {
            this.setState({
              selectedAddons: [],
              itemNumber: "1",
              priceID: "",
            });
          },
          (onError) => {
            // alert(onError);
          }
        );
      } else {
        let pid = p.prices.map((p) => p.id);
        const requestBody = {
          quantity: this.state.itemNumber,
          price_id: pid.toString(),
          user_id: storeUserId,
          product_id: p.info.id,
          addons: JSON.stringify(this.state.selectedAddons),
        };
        postClientDataResponse(
          clientHost + ADD_TO_CART,
          storeFresh.key,
          requestBody,
          (onSuccessResponse) => {
            this.setState({ selectedAddons: [], itemNumber: "1", priceID: "" });
          },
          (onError) => {
            // alert(onError);
          }
        );
      }
    }
    this.getCart();
  };
  numberOfItems = (e) => {
    this.setState({
      itemNumber: e.target.value.toString(),
    });
  };
  getDate = () => {
    var date = new Date().toLocaleString();
    this.setState({ date });
  };
  refreshPage = () => {
    this.setState({
      dlsSelected: false,

      catSelected: false,
      proSelected: false,
      product: null,
      customer: null,
      radio: "",
      measure: "",
      check: [],
      // categories: [],
      // products: [],
      filteredProducts: this.state.products,
      priceID: "",
      cart: [],
      // addons: [],
      selectedAddons: [],
      itemNumber: "1",
      cartPrice: {},
      pay: "",
      orderPlaced: false,
      // date: "",
      finalId: "",
      orderedItems: [],
      mobile: "",
      amount: "",
      change: "",
      customer_phone: "",
      cash_received: "",
      cash_balance: "",
    });
    this.getOrderDetails();
  };
  getOrderDetails = () => {
    var apiPath = new Uri(
      clientHost + `Report/getTodaySalesCount`
    ).addQueryParam("storeId", storeIdValue);
    getResponseClient(
      apiPath,
      storeFresh.key,
      (onSuccessResponse) => {
        onSuccessResponse.response.map((p) =>
          this.setState({
            totalOrders: p.OrdersCount,
            totalSales: p.TotalAmount,
            netSales: p.NetSale,
            average: p.Average,
          })
        );
      },
      (onError) => {
        // alert(onError);
      }
    );
  };
  render() {
    const styles = {
      margin: { margin: "0 5px 0 5px", color: "#000000", display: "inline" },
      // marginLeft10: { margin: "0 0 0 10px" },
      marginAll10: {
        margin: "10px 10px 10px 10px",
        width: "20px",
        height: "20px",
      },
      imgSmall: { margin: "0 10px 0 20px", width: "30px", height: "30px" },
      imageAdd: { margin: "0 0 0 8px", width: "50px", height: "50px" },
      imageMenu: { margin: "0 0 0 10px", width: "40px", height: "40px" },
      green: { color: "#008000" },
      tdCart: { width: "150px", padding: "5px" },
      tdCartLarge: { width: "200px", padding: "5px" },
      tdSmall: { width: "40px", padding: "5px" },
      red: { color: "#FF0000" },
    };
    let payOption = this.state.pay;
    let totalPrice;
    if (this.state.cartPrice) {
      if (
        this.state.cartPrice.itemsTotal !== null ||
        this.state.cartPrice.itemsTotal !== undefined ||
        this.state.cartPrice.addonTotal !== null ||
        this.state.cartPrice.addonTotal !== undefined
      ) {
        totalPrice =
          Number(this.state.cartPrice.itemsTotal) +
          Number(this.state.cartPrice.addonTotal);
      }
    }
    // const products = this.state.products
    //   ? this.state.products !== [] || this.state.products.null
    //     ? this.state.products.filter(
    //         (q) => q.info.category_id === this.state.catId
    //       )
    //     : null
    //   : null;

    // this.setState({
    //   selectedCatProducts: products,
    //   filteredProducts:products
    // });

    // let { selectedCatProducts } = this.state;
    // selectedCatProducts = products;

    // let { filteredProducts } = this.state;
    // filteredProducts = selectedCatProducts;

    // const filteredProducts = selectedCatProducts;
    const {
      addons,
      product,
      cart,
      deals,
      dealItems,
      dealItemsDetails,
      // categories,
    } = this.state;
    return (
      <div>
        <div className="content">
          <Table>
            <tbody>
              <tr>
                {deals
                  ? deals !== [] || deals !== null
                    ? deals
                        .filter((q) => q.customize === "YES")
                        .map((prop, key) => {
                          console.log(prop);
                          return (
                            <td
                              className="font-icon-list"
                              key={key}
                              onClick={() => this.onDls(prop)}
                            >
                              {prop.image !== "" ? (
                                <Image
                                  src={
                                    clientHost.split("/api")[0] +
                                    `/` +
                                    IMG_PATH_CATEGORY +
                                    // IMG_PATH_DEALS +

                                    `/` +
                                    prop.id +
                                    `/` +
                                    prop.image_path
                                  }
                                  alt={prop.name}
                                  style={styles.imageAdd}
                                />
                              ) : null}
                              <br />
                              {prop.type}
                            </td>
                          );
                        })
                    : null
                  : null}
                {/* {categories
                  ? categories !== [] || categories !== null
                    ? categories.map((prop, key) => {
                        return (
                          <td
                            className="font-icon-list"
                            key={key}
                            onClick={() => this.onCat(prop)}
                          >
                            {prop.image !== "" ? (
                              <Image
                                src={
                                  clientHost.split("/api")[0] +
                                  `/` +
                                  IMG_PATH_CATEGORY +
                                  `/` +
                                  prop.id +
                                  `/` +
                                  prop.image
                                }
                                alt={prop.name}
                                style={styles.imageAdd}
                              />
                            ) : null}
                            <br />
                            {prop.name}
                          </td>
                        );
                      })
                    : null
                  : null} */}
                {/* <td className="numbers">
                  <p>
                    No. of Orders:{" "}
                    <strong>
                      {isNaN(this.state.totalOrders)
                        ? null
                        : this.state.totalOrders}
                    </strong>
                    <br />
                    Gross Sale:{" "}
                    <strong>
                      {isNaN(this.state.totalSales)
                        ? null
                        : Math.round(this.state.totalSales)}{" "}
                      ₹
                    </strong>
                    <br />
                    Net Sale:{" "}
                    <strong>
                      {isNaN(this.state.netSales)
                        ? null
                        : Math.round(this.state.netSales)}{" "}
                      ₹
                    </strong>
                    <br />
                    APC:{" "}
                    <strong>
                      {isNaN(this.state.average)
                        ? null
                        : Math.round(this.state.average)}
                    </strong>
                  </p>
                </td> */}
              </tr>
            </tbody>
          </Table>
        </div>
        {/* <div>
          <Grid fluid>
            <Row>
              {this.state.search === true ? (
                <Col md={6}>
                  <Card
                    title="Products"
                    ctTableResponsive
                    content={
                      <div className="search-box">
                         <input
                          onChange={(e) => this.searchInitiate(e.target.value)}
                          type="search"
                          placeholder="Enter Product Name..."
                          className="form-control"
                          name="searchValue"
                          value={this.state.searchValue}
                        />
                      </div>
                    }
                  />
                </Col>
              ) : null}
            </Row>
          </Grid>
        </div> */}
        {/* {dealItems
          ? dealItems !== [] || dealItems !== null
            ? dealItems.map((prop, key) => prop.items)
            : null
          : null} */}
        <div>
          <Grid fluid>
            <Row>
              {this.state.dlsSelected === true ? (
                // {this.state.catSelected === true ||
                // this.state.searchValue !== "" ?
                <Col md={6}>
                  <Card
                    title="Products"
                    ctTableResponsive
                    content={
                      <Table striped hover>
                        <tbody>
                          {/* {this.state.filteredProducts
                            .filter(
                              (p) =>
                                (p.info.is_deleted === "N" ||
                                  p.info.status === "1") &&
                                p.prices !== null &&
                                p.info.store_id === storeIdValue
                            )  */}
                          {dealItems
                            ? dealItems !== [] || dealItems !== null
                              ? // .filter(
                                //   (p) => p.status === "ACTIVE"

                                //   // (p.info.is_deleted === "N" ||
                                //   //   p.info.status === "1") &&
                                //   // p.prices !== null &&
                                //   // p.info.store_id === storeIdValue
                                // )
                                dealItems.map((prop, key) => {
                                  return (
                                    <>
                                      <tr key={key}>
                                        <td>
                                          <strong>{prop.title}</strong>
                                        </td>
                                        <td>{prop.items}</td>
                                        <td>
                                          <Image
                                            roundedcircle="true"
                                            src={imgsrc}
                                            style={styles.imageMenu}
                                            key={key}
                                            onClick={() => this.addToCart(prop)}
                                          ></Image>
                                        </td>
                                      </tr>
                                      {dealItemsDetails
                                        ? dealItemsDetails !==
                                          ([] || null || "" || " " || undefined)
                                          ? dealItemsDetails.map(
                                              (prop, key) => (
                                                <>
                                                  <tr>
                                                    <td
                                                      style={styles.tdCartLarge}
                                                    >
                                                      {prop.food_type ===
                                                      "VEG" ? (
                                                        <i
                                                          className="fa fa-dot-circle-o"
                                                          style={styles.green}
                                                        ></i>
                                                      ) : null}
                                                      {prop.food_type ===
                                                      "NON-VEG" ? (
                                                        <i
                                                          className="fa fa-dot-circle-o"
                                                          style={styles.red}
                                                        ></i>
                                                      ) : null}
                                                      {prop.food_type ===
                                                      "BVG" ? (
                                                        <i
                                                          className="fa fa-dot-circle-o"
                                                          style={{
                                                            color: "blue",
                                                          }}
                                                        ></i>
                                                      ) : null}
                                                      {prop.name}
                                                    </td>
                                                    <td></td>
                                                    <td>
                                                      <Image
                                                        roundedcircle="true"
                                                        src={
                                                          IMG_PATH_PRODUCT +
                                                          `${prop.id}/${prop.image_path}`
                                                        }
                                                        style={styles.imageMenu}
                                                        key={key}
                                                        // onClick={() => this.addToCart(prop) }
                                                      ></Image>
                                                    </td>
                                                  </tr>
                                                </>
                                              )
                                            )
                                          : null
                                        : null}
                                      {prop.items
                                        ? prop.items !== [] || null || ""
                                          ? this.state.products
                                              .filter((m) =>
                                                m.info.id.includes(
                                                  prop.items.split(",")[0]
                                                )
                                              )
                                              .map((n) => (
                                                <td
                                                // onClick={() =>
                                                //   this.addDealItemToCart(n)
                                                // }
                                                >
                                                  {n.info.name}
                                                  <br />
                                                  <Image
                                                    roundedcircle="true"
                                                    src={
                                                      IMG_PATH_PRODUCT +
                                                      n.info.id +
                                                      "/" +
                                                      n.info.image_path
                                                    }
                                                    style={styles.imageMenu}
                                                    // key={key}
                                                    // onClick={() => this.addToCart("prop")}
                                                  />
                                                </td>
                                              ))
                                          : null
                                        : null}
                                      {prop.category_list
                                        ? prop.category_list !== null ||
                                          prop.category_list !== "" ||
                                          prop.category_list !== []
                                          ? prop.category_list.map((l) => (
                                              <tr>
                                                {this.state.products
                                                  .filter(
                                                    (m) =>
                                                      m.info.category_id ===
                                                      l.id
                                                  )
                                                  .map((n) => (
                                                    <td>
                                                      {
                                                        n.info.name.split(
                                                          " "
                                                        )[0]
                                                      }
                                                      &nbsp;
                                                      {
                                                        n.info.name.split(
                                                          " "
                                                        )[1]
                                                      }
                                                      <br />
                                                      {
                                                        n.info.name.split(
                                                          " "
                                                        )[2]
                                                      }
                                                      &nbsp;
                                                      {
                                                        n.info.name.split(
                                                          " "
                                                        )[3]
                                                      }
                                                      <br />
                                                      {
                                                        n.info.name.split(
                                                          " "
                                                        )[4]
                                                      }
                                                      &nbsp;
                                                      {
                                                        n.info.name.split(
                                                          " "
                                                        )[5]
                                                      }
                                                      <Image
                                                        roundedcircle="true"
                                                        src={
                                                          IMG_PATH_PRODUCT +
                                                          n.info.id +
                                                          "/" +
                                                          n.info.image_path
                                                        }
                                                        style={styles.imageMenu}
                                                        // key={key}
                                                        // onClick={() => this.addToCart("prop")}
                                                      />
                                                    </td>
                                                  ))}
                                              </tr>
                                            ))
                                          : null
                                        : null}
                                    </>
                                    // <tr key={key}>
                                    //   <td>
                                    //     {prop.info.food_type === "VEG" ? (
                                    //       <i
                                    //         className="fa fa-dot-circle-o"
                                    //         style={styles.green}
                                    //       ></i>
                                    //     ) : null}
                                    //     {prop.info.food_type === "NON-VEG" ? (
                                    //       <i
                                    //         className="fa fa-dot-circle-o"
                                    //         style={styles.red}
                                    //       ></i>
                                    //     ) : null}
                                    //     {prop.info.food_type === "BVG" ? (
                                    //       <i
                                    //         className="fa fa-dot-circle-o"
                                    //         style={{ color: "blue" }}
                                    //       ></i>
                                    //     ) : null}
                                    //   </td>
                                    //   <td style={styles.tdCartLarge}>
                                    //     {prop.info.name}
                                    //     <br />
                                    //     {prop.prices
                                    //       ? prop.prices.length > 1
                                    //         ? prop.prices.map((p, index) => (
                                    //             <label
                                    //               key={index}
                                    //               style={styles.margin}
                                    //             >
                                    //               <input
                                    //                 radio-inline="true"
                                    //                 style={styles.margin}
                                    //                 type="radio"
                                    //                 id={p.measure}
                                    //                 name={p.product_id}
                                    //                 value={p.price_id}
                                    //                 onChange={() =>
                                    //                   this.onRadioChanged(p)
                                    //                 }
                                    //               />
                                    //               {p.quantity}&nbsp;{p.measure}{" "}
                                    //               <i
                                    //                 className="fa fa-inr"
                                    //                 aria-hidden="true"
                                    //               ></i>
                                    //               {p.offer_price !== "0" ? (
                                    //                 <strong>{p.offer_price}</strong>
                                    //               ) : (
                                    //                 <strong>{p.price}</strong>
                                    //               )}
                                    //             </label>
                                    //           ))
                                    //         : prop.prices.map((p, index) => (
                                    //             <div key={index}>
                                    //               {p.measure === "None" ||
                                    //               "NONE" ||
                                    //               "none" ? (
                                    //                 <>
                                    //                   {p.quantity}&nbsp;{p.measure}
                                    //                 </>
                                    //               ) : null}{" "}
                                    //               <i
                                    //                 className="fa fa-inr"
                                    //                 aria-hidden="true"
                                    //               ></i>
                                    //               {p.offer_price !== "0" ? (
                                    //                 <strong>{p.offer_price}</strong>
                                    //               ) : (
                                    //                 <strong>{p.price}</strong>
                                    //               )}
                                    //             </div>
                                    //           ))
                                    //       : null}
                                    //   </td>
                                    //   <td>
                                    //     <select
                                    //       onChange={this.numberOfItems}
                                    //       defaultValue={"1"}
                                    //     >
                                    //       <option value="1">01</option>
                                    //       <option value="2">02</option>
                                    //       <option value="3">03</option>
                                    //       <option value="4">04</option>
                                    //       <option value="5">05</option>
                                    //     </select>
                                    //   </td>
                                    //   <td>
                                    //     {this.state.addonStatus === "YES" ? (
                                    //       <Image
                                    //         key={key}
                                    //         roundedcircle="true"
                                    //         src={imgsrc}
                                    //         style={styles.imageMenu}
                                    //         onClick={() => this.onProduct(prop)}
                                    //       ></Image>
                                    //     ) : (
                                    //       <Image
                                    //         roundedcircle="true"
                                    //         src={imgsrc}
                                    //         style={styles.imageMenu}
                                    //         key={key}
                                    //         onClick={() => this.addToCart(prop)}
                                    //       ></Image>
                                    //     )}
                                    //   </td>
                                    // </tr>
                                  );
                                })
                              : null
                            : null}
                          {(this.state.freeOne !== "" ||
                            this.state.freeTwo !== "") &&
                          !dealItems ? (
                            <tr>
                              <td></td>
                              <td></td>
                              <td>
                                <Image
                                  roundedcircle="true"
                                  src={imgsrc}
                                  style={styles.imageMenu}
                                  // key={key}
                                  onClick={() => this.addToCart("prop")}
                                ></Image>
                              </td>
                            </tr>
                          ) : null}
                          {this.state.freeOne !== "" ? (
                            <tr>
                              <td>{this.state.freeOne}</td>
                              {/* <td>
                                            <Image
                                              roundedcircle="true"
                                              src={
                                                IMG_PATH_PRODUCT +
                                                `${prop.id}/${prop.image_path}`
                                              }
                                              style={styles.imageMenu}
                                              key={key}
                                             ></Image>
                                          </td> */}
                            </tr>
                          ) : null}
                          {this.state.freeTwo !== "" ? (
                            <tr>
                              <td>{this.state.freeTwo}</td>
                              {/* <td>
                                            <Image
                                              roundedcircle="true"
                                              src={
                                                IMG_PATH_PRODUCT +
                                                `${prop.id}/${prop.image_path}`
                                              }
                                              style={styles.imageMenu}
                                              key={key}
                                            ></Image>
                                          </td> */}
                            </tr>
                          ) : null}
                        </tbody>
                      </Table>
                    }
                  />
                </Col>
              ) : null}
              {this.state.proSelected === true ? (
                <Col md={6}>
                  <div>
                    <Image
                      onClick={this.getCart}
                      src={refreshImg}
                      style={styles.marginAll10}
                    ></Image>
                    <Image
                      onClick={this.deleteCart}
                      src={deletecartAll}
                      style={styles.marginAll10}
                    ></Image>
                    <label style={styles.margin}>
                      <input
                        type="radio"
                        id="cash"
                        name="pay"
                        value="cash"
                        onChange={this.onPaytype}
                      />
                      <span style={{ fontSize: "13px" }}>&nbsp; Cash</span>
                    </label>
                    <span> </span>
                    <label>
                      <input
                        type="radio"
                        id="online"
                        name="pay"
                        value="online"
                        onChange={this.onPaytype}
                      />
                      <span style={{ fontSize: "13px" }}>
                        &nbsp; Online&nbsp;
                      </span>
                    </label>
                    <span> </span>
                    <label>
                      <input
                        type="radio"
                        id="swipe"
                        name="pay"
                        value="swipe"
                        onChange={this.onPaytype}
                      />
                      <span style={{ fontSize: "13px" }}>&nbsp; Swipe</span>
                    </label>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    {this.state.orderPlaced === false ? (
                      <Image
                        onClick={this.onOrderVerify}
                        src={orderImg}
                        style={styles.imgSmall}
                      ></Image>
                    ) : null}
                    {this.state.orderPlaced === true ? (
                      <ReactToPrint
                        onAfterPrint={this.refreshPage}
                        trigger={() => (
                          <Image
                            src={printerImg}
                            style={styles.imgSmall}
                          ></Image>
                        )}
                        content={() => this.componentRef}
                      />
                    ) : null}
                  </div>
                  <div className="numbers">
                    {isNaN(this.state.cartPrice) ? (
                      <div className="form-group">
                        <span style={{ fontSize: "20px" }}>
                          Total:
                          {Math.round(
                            this.state.cartPrice
                              ? this.state.cartPrice.cartTotal
                              : 0
                          )}
                        </span>{" "}
                        <span> </span>{" "}
                        {/* <input
                          style={{ fontSize: "12px", fontWeight: "bold" }}
                          id="mobile"
                          size="11"
                          type="text"
                          name="mobile"
                          required
                          // className="input-material"
                          onChange={this.onFieldChange}
                          placeholder="mobile"
                          maxLength={10}
                        /> */}
                        {payOption === "cash" ? (
                          <>
                            {" "}
                            <input
                              id="amount"
                              size="8"
                              type="text"
                              name="amount"
                              required
                              // className="input-material"
                              onChange={this.onFieldChange}
                              placeholder="amount"
                              style={{ fontSize: "12px", fontWeight: "bold" }}
                            />{" "}
                            <input
                              style={{ fontSize: "12px", fontWeight: "bold" }}
                              id="change"
                              size="6"
                              type="text"
                              name="change"
                              required
                              // className="input-material"
                              onChange={this.onFieldChange}
                              placeholder="change"
                              value={
                                this.state.amount === ""
                                  ? ""
                                  : Number(this.state.amount) -
                                    Math.round(
                                      totalPrice + (5 / 100) * totalPrice
                                    )
                              }
                              readOnly
                            />
                          </>
                        ) : null}
                      </div>
                    ) : null}
                  </div>
                  <Card
                    title="Cart"
                    ctTableResponsive
                    content={
                      <Table striped hover>
                        {/* {cart
                          ? cart !==
                            ([] || null || "" || " " || undefined)
                        ? cart.map((prop, key) => {
                        // {dealItemsDetails
                        //   ? dealItemsDetails !==
                        //     ([] || null || "" || " " || undefined)
                        //     ? dealItemsDetails.map((prop, key) => {
                                // const productMeasure = prop.productInfo;

                                return (
                                  <tbody key={key}>
                                    <tr>
                                      <td style={styles.tdCartLarge}>
                                        {prop.food_type === "VEG" ? (
                                          <i
                                            className="fa fa-dot-circle-o"
                                            style={styles.green}
                                          ></i>
                                        ) : null}
                                        {prop.food_type === "NON-VEG" ? (
                                          <i
                                            className="fa fa-dot-circle-o"
                                            style={styles.red}
                                          ></i>
                                        ) : null}
                                        {prop.food_type === "BVG" ? (
                                          <i
                                            className="fa fa-dot-circle-o"
                                            style={{ color: "blue" }}
                                          ></i>
                                        ) : null}
                                        {prop.name}
                                      </td>
                                      <td style={styles.tdCart}>
                                        {prop.measure === "None" ||
                                        prop.measure === "NONE"
                                          ? null
                                          : prop.measure}{" "}
                                        {prop.quantity} Qty
                                        <br />
                                        <i
                                          className="fa fa-inr"
                                          aria-hidden="true"
                                        ></i>
                                        {prop.cartTotal} (including Tax)
                                      </td>
                                      <td>
                                        {prop.addons !== null
                                          ? prop.addons
                                            ? prop.addons.map((p, index) => (
                                                <>
                                                  -{p.name},
                                                  <br />
                                                </>
                                              ))
                                            : null
                                          : null}
                                      </td>
                                      <td style={styles.tdSmall}>
                                        <Image
                                          key={key}
                                          onClick={() =>
                                            this.removeFromCart(prop)
                                          }
                                          // roundedcircle="true"
                                          src={deletecartIcon}
                                          style={styles.imgSmall}
                                        ></Image>
                                      </td>
                                    </tr>
                                  </tbody>
                                );
                              })
                            : null
                          : null} */}
                        {cart
                          ? cart !== ([] || null || "" || " " || undefined)
                            ? cart.map((prop, key) => {
                                // const productMeasure = prop.productInfo;

                                return (
                                  <tbody key={key}>
                                    <tr>
                                      <td style={styles.tdCartLarge}>
                                        {prop.food_type === "VEG" ? (
                                          <i
                                            className="fa fa-dot-circle-o"
                                            style={styles.green}
                                          ></i>
                                        ) : null}
                                        {prop.food_type === "NON-VEG" ? (
                                          <i
                                            className="fa fa-dot-circle-o"
                                            style={styles.red}
                                          ></i>
                                        ) : null}
                                        {prop.food_type === "BVG" ? (
                                          <i
                                            className="fa fa-dot-circle-o"
                                            style={{ color: "blue" }}
                                          ></i>
                                        ) : null}
                                        {prop.name}
                                      </td>
                                      <td style={styles.tdCart}>
                                        {prop.measure === "None" ||
                                        prop.measure === "NONE"
                                          ? null
                                          : prop.measure}{" "}
                                        {prop.quantity} Qty
                                        <br />
                                        <i
                                          className="fa fa-inr"
                                          aria-hidden="true"
                                        ></i>
                                        {prop.cartTotal} (including Tax)
                                      </td>
                                      <td>
                                        {prop.addons !== null
                                          ? prop.addons
                                            ? prop.addons.map((p, index) => (
                                                <>
                                                  -{p.name},
                                                  <br />
                                                </>
                                              ))
                                            : null
                                          : null}
                                      </td>
                                      <td style={styles.tdSmall}>
                                        <Image
                                          key={key}
                                          onClick={() =>
                                            this.removeFromCart(prop)
                                          }
                                          // roundedcircle="true"
                                          src={deletecartIcon}
                                          style={styles.imgSmall}
                                        ></Image>
                                      </td>
                                    </tr>
                                  </tbody>
                                );
                              })
                            : null
                          : null}
                      </Table>
                    }
                  />
                  <div style={{ display: "none" }}>
                    <div ref={(el) => (this.componentRef = el)}>
                      <table>
                        <thead>
                          <tr>
                            <th style={{ textAlign: "center" }}>{storeName}</th>
                          </tr>
                          {/* <tr>
                            <th style={{ textAlign: "center" }}>
                              M S INVESTMENTS
                            </th>
                          </tr> */}
                          {storeAddress ? (
                            storeAddress !== "" ? (
                              <>
                                {/* <tr>
                                <th style={{ textAlign: "center" }}>
                                  {storeAddress.split(", ")[0]},
                                </th>
                              </tr> */}
                                <tr>
                                  <th style={{ textAlign: "center" }}>
                                    {storeAddress.split(", ")[1]},{" "}
                                    {storeAddress.split(", ")[2]},
                                  </th>
                                </tr>
                                <tr>
                                  <th style={{ textAlign: "center" }}>
                                    {storeAddress.split(", ")[3]},{" "}
                                    {storeAddress.split(", ")[4]},
                                  </th>
                                </tr>
                                <tr>
                                  <th style={{ textAlign: "center" }}>
                                    {storeAddress.split(", ")[5]}
                                  </th>
                                </tr>
                              </>
                            ) : null
                          ) : null}
                          {/* <tr>
                            <th style={{ textAlign: "center" }}>{storeName}</th>
                          </tr> */}
                          {/* <tr>
                            <td>----------------------------</td>
                          </tr>
                          <tr>
                            <th>{storeName}</th>
                          </tr> */}
                          <tr>
                            <td>----------------------------</td>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Bill No: {this.state.finalId}</td>
                          </tr>
                          <tr>
                            <td>
                              Order No: {/* {storeCode}- */}
                              {isNaN(this.state.totalOrders)
                                ? null
                                : Number(this.state.totalOrders) + 1}
                            </td>
                          </tr>
                          {/* <tr>
                            <td>Delivery Boy: xx.</td>
                          </tr> */}
                          {/* <tr>
                            <td>Cashier: Cashier.</td>
                          </tr> */}
                          <tr>
                            <td>Date:{this.state.date}</td>
                          </tr>
                          <tr>
                            <td>----------------------------</td>
                          </tr>
                          <tr>
                            <th>
                              Menu&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                              Qty&nbsp;&nbsp;Rate&nbsp;&nbsp;&nbsp;Amt
                            </th>
                          </tr>
                          <tr>
                            <td>----------------------------</td>
                          </tr>
                          {this.state.cart
                            ? this.state.cart.map((p, index) => (
                                <>
                                  <tr key={index}>
                                    {/* <td>
                                      {p.name.length > 12 ? (
                                        <>{p.name.substring(0, 11)}...</>
                                      ) : (
                                        <>{p.name} </>
                                      )}{" "}
                                      {p.quantity}&nbsp;&nbsp;
                                      {p.itemPrice}&nbsp;&nbsp;
                                      {p.quantity * p.itemPrice}
                                    </td> */}
                                  </tr>
                                  {/* <tr>
                                    <td>
                                      {p.measure === "None" ||
                                      p.measure === "NONE"
                                        ? null
                                        : p.measure}{" "}
                                    </td>
                                  </tr> */}
                                  {p.addons
                                    ? p.addons !== [] || null
                                      ? p.addons.map((r, index) => (
                                          <tr key={index}>
                                            <td>
                                              &nbsp;-
                                              {r.name.length > 10 ? (
                                                <>{r.name.substring(0, 9)}...</>
                                              ) : (
                                                <>{r.name} </>
                                              )}
                                              {p.quantity}
                                            </td>
                                          </tr>
                                        ))
                                      : null
                                    : null}
                                </>
                              ))
                            : null}
                          {/* {this.state.orderedItems !== []
                            ? this.state.orderedItems.map((p, index) => (
                                <>
                                  <tr key={index}>
                                    <td>
                                      {p.item.name.length > 12 ? (
                                        <>{p.item.name.substring(0, 11)}...</>
                                      ) : (
                                        <>{p.item.name} </>
                                      )}
                                      {p.item.quantity}&nbsp;&nbsp;
                                      {p.item.itemPrice}
                                      &nbsp;&nbsp;
                                      {p.item.total_price}
                                    </td>
                                  </tr>
                                  {p.addons
                                    ? p.addons !== [] || null
                                      ? p.addons.map((r, index) => (
                                          <tr key={index}>
                                            <td>
                                              &nbsp;-
                                              {r.name.length > 10 ? (
                                                <>{r.name.substring(0, 9)}...</>
                                              ) : (
                                                <>{r.name} </>
                                              )}
                                              {p.quantity}&nbsp;&nbsp;
                                              {r.price}&nbsp;&nbsp;
                                              {p.quantity * r.price}
                                            </td>
                                          </tr>
                                        ))
                                      : null
                                    : null}
                                </>
                              ))
                            : null} */}
                          <tr>
                            <td>&nbsp;</td>
                          </tr>
                          {/* <tr>
                            <td>
                              Total Qty:{" "}
                              {this.state.cart ? this.state.cart.length : null}
                            </td>
                          </tr> */}
                          <tr>
                            <td>
                              SubTotal:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Rs.
                              {totalPrice}
                              {/* {this.state.orderedItems !== []
                                ? //  &&this.state.orderedItems.total_price !== []
                                  this.state.orderedItems.reduce(
                                    (p, q) => p + Number(q.item.total_price),
                                    0
                                  )
                                : null} */}
                            </td>
                          </tr>
                          <tr>
                            <td>----------------------------</td>
                          </tr>
                          {/* <tr>
                            <td>
                              GST
                              5%&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Rs.
                              {Math.round((5 / 100) * totalPrice * 100) / 100}
                            </td>
                          </tr> */}
                          <tr>
                            <td>
                              &nbsp;&nbsp;CGST 2.5%&nbsp;&nbsp;&nbsp;&nbsp;Rs.
                              {Math.round((2.5 / 100) * totalPrice * 100) / 100}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              &nbsp;&nbsp;SGST 2.5%&nbsp;&nbsp;&nbsp;&nbsp;Rs.
                              {Math.round((2.5 / 100) * totalPrice * 100) / 100}
                            </td>
                          </tr>
                          <tr>
                            <td>----------------------------</td>
                          </tr>
                          <tr>
                            <td>
                              Total
                              Amount&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Rs.
                              {Math.round(
                                this.state.cartPrice.cartTotal * 100
                              ) / 100}
                              {/* {this.state.orderedItems !== []
                                ? this.state.orderedItems.reduce(
                                    (p, q) => p + Number(q.item.total_price),
                                    0
                                  )
                                : null} */}
                            </td>
                          </tr>
                          {/* <tr>
                            <td>----------------------------</td>
                          </tr> */}
                          <tr style={{ textAlign: "center" }}>
                            <td>
                              <strong>
                                PAY: Rs.
                                {Math.round(this.state.cartPrice.cartTotal)}
                              </strong>
                              {/* {Math.round(
                                totalPrice + (5 / 100) * totalPrice
                              )}{" "} */}
                              {/* {this.state.orderedItems !== []
                                ? this.state.orderedItems.reduce(
                                    (p, q) => p + Number(q.item.total_price),
                                    0
                                  )
                                : null} */}
                            </td>
                          </tr>
                          {/* <tr>
                            <th>----------------------------</th>
                          </tr> */}
                          {/* <tr>
                            <td>Mode of Payment: {payOption}</td>
                          </tr> */}
                          <tr>
                            <td>----------------------------</td>
                          </tr>
                          {/* <tr>
                            <td>PAN &nbsp;&nbsp;: AARFM6691C</td>
                          </tr> */}
                          <tr style={{ textAlign: "center" }}>
                            <td>GSTIN : {gst}</td>
                          </tr>
                          {/* <tr>
                            <td>PAN : {pan}</td>
                          </tr> */}
                          <tr style={{ textAlign: "center" }}>
                            <td>Thank you, visit again</td>
                          </tr>
                          <tr style={{ textAlign: "center" }}>
                            <td>Please check your belongings</td>
                          </tr>
                          <tr style={{ textAlign: "center" }}>
                            <td>before you leave</td>
                          </tr>
                          {/* <tr>
                            <td>----------------------------</td>
                          </tr> */}
                          <tr style={{ textAlign: "center" }}>
                            <td>Powered by - TreoSoft IT</td>
                          </tr>
                          {/* <tr>
                            <td>http://treosoftit.com/</td>
                          </tr> */}
                        </tbody>
                      </table>
                      <table id="kots">
                        <thead>
                          <tr>
                            <th style={{ textAlign: "center" }}>
                              Bill No:
                              {/* {storeCode}- */}
                              {this.state.finalId}
                            </th>
                          </tr>
                          <tr>
                            <td>Date:{this.state.date}</td>
                          </tr>
                          <tr>
                            <td>----------------------------</td>
                          </tr>
                          <tr>
                            <td>
                              Kots.{" "}
                              {isNaN(this.state.totalOrders)
                                ? null
                                : Number(this.state.totalOrders) + 1}
                            </td>
                          </tr>
                          <tr>
                            <td>----------------------------</td>
                          </tr>
                        </thead>
                        <tbody>
                          {/* {this.state.orderedItems !== []
                            ? this.state.orderedItems.map((p, index) => (
                                <>
                                  <tr key={index}>
                                    <td>
                                      {p.item.name} -{p.item.quantity}
                                    </td>
                                  </tr>
                                  <tr>
                                    {p.item.size === "None" ||
                                    p.item.size === "NONE" ||
                                    p.item.size === "none" ? (
                                      <td></td>
                                    ) : (
                                      <td>{p.item.size}</td>
                                    )}
                                  </tr>
                                  {p.addons !== []
                                    ? p.addons.map((q, index) => (
                                        <tr key={index}>
                                          <td>&nbsp;-{q.name}</td>
                                        </tr>
                                      ))
                                    : null}
                                </>
                              ))
                            : null} */}
                          {this.state.cart
                            ? this.state.cart.map((p, index) => (
                                <>
                                  <tr key={index}>
                                    <td>
                                      {
                                        p.name
                                        //   .length >= 14 ? (
                                        //   <>{p.name.substring(0, 13)}...</>
                                        // ) : p.name.length <= 7 ? (
                                        //   <>
                                        //     {p.name}
                                        //     &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        //   </>
                                        // ) : (
                                        //   <>{p.name}&nbsp;&nbsp;</>
                                        // )
                                      }{" "}
                                      -{p.quantity}
                                      {/* &nbsp;&nbsp;
                                      {p.itemPrice}
                                      &nbsp;&nbsp;
                                      {p.quantity * p.itemPrice} */}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      {p.measure === "None" ||
                                      p.measure === "NONE"
                                        ? null
                                        : p.measure}{" "}
                                    </td>
                                  </tr>
                                  {p.addons
                                    ? p.addons !== [] || null
                                      ? p.addons.map((r, index) => (
                                          <tr key={index}>
                                            <td>
                                              &nbsp;-
                                              {r.name} {p.quantity}&nbsp;&nbsp;
                                              {/* {r.price}&nbsp;&nbsp;
                                              {p.quantity * r.price} */}
                                            </td>
                                          </tr>
                                        ))
                                      : null
                                    : null}
                                </>
                              ))
                            : null}
                          {/* {this.state.cart !== []
                            ? this.state.orderedItems.map((p, index) => (
                                <>
                                  <tr key={index}>
                                    <td>
                                      {p.item.name} -{p.item.quantity}
                                    </td>
                                  </tr>
                                  <tr>
                                    {p.item.size === "None" ||
                                    p.item.size === "NONE" ||
                                    p.item.size === "none" ? (
                                      <td></td>
                                    ) : (
                                      <td>{p.item.size}</td>
                                    )}
                                  </tr>
                                  {p.addons !== []
                                    ? p.addons.map((q, index) => (
                                        <tr key={index}>
                                          <td>&nbsp;-{q.name}</td>
                                        </tr>
                                      ))
                                    : null}
                                </>
                              ))
                            : null} */}
                          {/* <tr>
                            <td>----------------------------</td>
                          </tr> */}
                          {/* <tr style={{ textAlign: "center" }}>
                            <td>
                              Bill Total: Rs.
                              <strong>
                                {Math.round(this.state.cartPrice.cartTotal)}
                              </strong>
                            </td>
                          </tr> */}
                          <tr>
                            <td>----------------------------</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </Col>
              ) : null}
            </Row>
          </Grid>
        </div>
        <div className="content">
          <Grid fluid>
            <Row>
              <Col md={6}>
                {product && (
                  <Modal
                    isOpen={true}
                    onRequestClose={this.closeModal}
                    ariaHideApp={false}
                    className="Modal"
                    scrollable={true}
                  >
                    <Zoom scrollable={true}>
                      <Card
                        title={product.info.name}
                        content={
                          <>
                            <div className="modal-header">
                              <Button
                                bsStyle="info"
                                // pullright="true"
                                type="submit"
                                onClick={() => this.addToCart(product)}
                              >
                                <span
                                  style={{
                                    fontSize: "15px",
                                    fontWeight: "bold",
                                    color: "white",
                                  }}
                                >
                                  Skip
                                </span>
                              </Button>
                              &nbsp;&nbsp;
                              <Button
                                bsStyle="info"
                                type="submit"
                                onClick={() => this.checkAddons(product)}
                              >
                                <span
                                  style={{
                                    fontSize: "15px",
                                    fontWeight: "bold",
                                    color: "white",
                                  }}
                                >
                                  Add n continue
                                </span>
                              </Button>
                            </div>
                            <div className="table-full-width" id="modal-body">
                              <table className="table">
                                <tbody>
                                  {addons
                                    ? addons.map((p, index) => (
                                        <tr key={index}>
                                          <td>
                                            <div className="checkbox checkbox-inline">
                                              <input
                                                id={p.id}
                                                type="checkbox"
                                                onChange={() =>
                                                  this.handleAddon(p, product)
                                                }
                                              />
                                              <label htmlFor={p.id}></label>
                                            </div>
                                          </td>
                                          <td>
                                            <strong>{p.name}</strong>
                                          </td>
                                          <td>{p.food_type}</td>
                                          {product.prices ? (
                                            product.prices.length > 1 ? (
                                              product.prices
                                                .filter(
                                                  (q) =>
                                                    q.id === this.state.priceID
                                                )
                                                .map((r, index) => (
                                                  <td key={index}>
                                                    {r.measure === "Regular"
                                                      ? p.price_regular
                                                      : p.price_medium}
                                                  </td>
                                                ))
                                            ) : (
                                              <td>{p.price_regular}</td>
                                            )
                                          ) : null}
                                        </tr>
                                      ))
                                    : null}
                                </tbody>
                              </table>
                            </div>
                          </>
                        }
                      />
                    </Zoom>
                  </Modal>
                )}
              </Col>
            </Row>
          </Grid>
        </div>
        {/* <div className="content">
          {customer && (
            <Modal
              isOpen={true}
              onRequestClose={this.closeModal}
              ariaHideApp={false}
              className="Modal"
              // scrollable={true}
            >
              <Zoom scrollable={true}>
                <Card
                  content={
                    <form>
                      <div className="form-group">
                        <input
                          id="mobile"
                          type="text"
                          name="mobile"
                          required
                          // className="input-material"
                          onChange={this.onFieldChange}
                          placeholder="mobile"
                        />
                      </div>
                      {payOption === "online" ||
                      payOption === "swipe" ? null : (
                        <>
                          <div className="form-group">
                            <input
                              id="amount"
                              type="text"
                              name="amount"
                              required
                              // className="input-material"
                              onChange={this.onFieldChange}
                              placeholder="amount"
                            />
                          </div>
                          <div className="form-group">
                            <input
                              id="change"
                              type="text"
                              name="change"
                              required
                              // className="input-material"
                              onChange={this.onFieldChange}
                              placeholder="change"
                              value={
                                this.state.amount === ""
                                  ? ""
                                  : Number(this.state.amount) -
                                    Math.round(
                                      totalPrice + (5 / 100) * totalPrice
                                    )
                              }
                              readOnly
                            />
                          </div>
                        </>
                      )}
                      <Button
                        bsStyle="info"
                        pullright="true"
                        fill="true"
                        // type="submit"
                        onClick={this.onForward}
                      >
                        Forward
                      </Button>
                    </form>
                  }
                />
              </Zoom>
            </Modal>
          )}
        </div> */}
      </div>
    );
  }

  // getStoreDetails = (sid, uid) => {
  //   const client = window.localStorage.getItem("clientAccess");
  //   storeFresh = JSON.parse(client);
  //   var apiPath3 = new Uri(storeFresh.host + STORE_LIST);
  //   getResponseClient(
  //     apiPath3,
  //     storeFresh.key,
  //     (onSuccessResponse) => {
  //       console.log(onSuccessResponse.result);
  //       let obj = onSuccessResponse.result.find((p) => p.id === sid);
  //       let access = {
  //         name: obj.name,
  //         mail: obj.mail,
  //         phone: obj.phone_one,
  //         role: obj.role,
  //         radius: obj.radius,
  //         userId: uid,
  //         storeId: sid,
  //         storeName: obj.name,
  //         longitude: obj.longitude,
  //         latitute: obj.latitute,
  //         storeAddress: obj.address,
  //         gst: obj.gst,
  //       };
  //       localStorage.setItem("access", JSON.stringify(access));
  //       // this.props.freshAccess(access);
  //       // this.setState({
  //       //   storeDetails: onSuccessResponse.response,
  //       // });
  //     },
  //     (onError) => {
  //       console.log(onError.message);
  //     }
  //   );
  // };
  async componentDidMount() {
    const person = [
      {
        name: "Jim",
        color: "blue",
        age: 22,
        date: "18/032021",
      },
      {
        name: "Sam",
        color: "blue",
        age: 33,
        date: "18/032021",
      },
      {
        name: "Eddie",
        color: "green",
        age: 77,
        date: "19/032021",
      },
    ];

    const groupBy = (array, key) =>
      array.reduce((result, currentValue) => {
        let a = [];
        (result[currentValue.date] = result[currentValue.date] || []).push(
          (a = [...a, currentValue])
        );
        return result;
      }, {});
    const personGroupedByColor = groupBy(person, "date");
    console.log(personGroupedByColor);
    // this.getStoreDetails(storeIdValue, storeUserId);
    this.getDate();
    var apiPath = new Uri(clientHost + ACTIVE_DEALS_LIST).addQueryParam(
      "storeid",
      storeFresh.storeId
    );
    getResponseClient(
      apiPath,
      storeFresh.key,
      (onSuccessResponse) => {
        this.setState({
          deals: onSuccessResponse.result,
          orderPlaced: false,
        });
      },
      (onError) => {
        // alert(onError.message);
      }
    );
    // var apiPath = new Uri(clientHost + CATEGORY_LIST).addQueryParam(
    //   "storeid",
    //   storeFresh.storeId
    // );
    // getResponseClient(
    //   apiPath,
    //   storeFresh.key,
    //   (onSuccessResponse) => {
    //     this.setState({
    //       categories: onSuccessResponse.result,
    //       orderPlaced: false,
    //     });
    //   },
    //   (onError) => {
    //     // alert(onError.message);
    //   }
    // );
    var apiPath2 = new Uri(clientHost + MENU_LIST).addQueryParam(
      "storeId",
      storeIdValue
    );
    getResponseClient(
      apiPath2,
      storeFresh.key,
      (onSuccessResponse) => {
        this.setState({
          products: onSuccessResponse.result,
          // .filter(
          //   (p) => p.info.store_id === storeIdValue
          // ),
          orderPlaced: false,
          filteredProducts: onSuccessResponse.result,
        });
      },
      (onError) => {
        // alert(onError.message);
      }
    );
    var apiPath3 = new Uri(
      clientHost + `addon/addonsByStore?storeId=${storeIdValue}`
    );
    getResponseClient(
      apiPath3,
      storeFresh.key,
      (onSuccessResponse) => {
        // alert('Addons Success');
        this.setState({
          addons: onSuccessResponse.result,
          orderPlaced: false,
        });
      },
      (onError) => {
        // alert('Addons Error');
      }
    );
    this.getOrderDetails();
  }
}
export default TakeOrder;
