import React from "react";
import {
  ADD_PINCODE,
  DELETE_PINCODE,
  PINCODE_LIST,
} from "../../Network/ApiPaths";
import PincodesComponent from "./PincodesComponent";
import getResponseClient from "../../Network/GetClientDataResponse";
import Uri from "../../Network/Uri";
import postClientDataResponse from "../../Network/PostClientDataResponse";
let clientHost;
let storeFresh;
class PincodesContainer extends React.Component {
  constructor(props) {
    super();
    const client = window.localStorage.getItem("clientAccess");
    storeFresh = JSON.parse(client);
    if (storeFresh !== null) {
      clientHost = JSON.parse(window.localStorage.getItem("clientAccess")).host;
    }
    this.state = {
      pincodes: [],
      deletedPinId: "",
      selectedPin: [],
      pName: "",
      dDate: "",
    };
  }
  onPinTriggerDelete = (e) => {
    this.setState({ deletedPinId: e });
  };
  deletePin = (dpid) => {
    // if (this.state.deletedPinId != "") {
    const d = this.state.deletedPinId;
    // } else {  d = dpid; }
    var apiPath = new Uri(clientHost + DELETE_PINCODE).addQueryParam("id", d);
    getResponseClient(
      apiPath,
      storeFresh.key,
      (onSuccessResponse) => {
        this.getPincodes();
      },
      (onError) => {
        console.log(onError.message);
      }
    );
  };
  onNameChange = (e) => {
    this.setState({ pName: e.target.value });
  };
  onNameUpdate = (e) => {
    this.setState({ newName: e.target.value });
  };
  onDateUpdate = (e) => {
    this.setState({ dDate: e.target.value });
  };
  initiatedEditing = (p) => {
    this.setState({
      selectedPin: p,
    });
  };
  editPin = () => {
    if (
      this.state.newName === "" ||
      this.state.newName === this.state.selectedPin.name
    ) {
      alert("no changes to update");
    }
    // else if (this.state.dDate === "") {alert("enter date");}
    else {
      let pid = this.state.selectedPin;
      const requestBody = {
        name: this.state.newName,
        delivary_date: this.state.dDate,
        // status: "ACTIVE",
        id: pid.id,
      };
      postClientDataResponse(
        clientHost + ADD_PINCODE,
        storeFresh.key,
        requestBody,
        (onSuccessResponse) => {
          this.getPincodes();
        },
        (onError) => {
          console.log(onError);
        }
      );
    }
  };
  onAdd = (e) => {
    e.preventDefault();
    this.setState({
      pName: this.state.pName,
    });
    const requestBody = {
      name: this.state.pName,
      status: "ACTIVE",
    };
    postClientDataResponse(
      clientHost + ADD_PINCODE,
      storeFresh.key,
      requestBody,
      (onSuccessResponse) => {
        this.getPincodes();
      },
      (onError) => {
        console.log(onError.message);
      }
    );
  };
  onChangePinStatus = (p) => {
    // if (p.status === "ACTIVE") {
    //   this.deletePin(p.id);
    // } else {

    let requestBody;
    if (p.status === "ACTIVE") {
      requestBody = {
        id: p.id,
        status: "DISABLED",
      };
    } else {
      requestBody = {
        id: p.id,
        status: "ACTIVE",
      };
    }
    postClientDataResponse(
      clientHost + ADD_PINCODE,
      storeFresh.key,
      requestBody,
      (onSuccessResponse) => {
        this.getPincodes();
      },
      (onError) => {
        console.log(onError);
      }
    );
    // }
  };
  render() {
    return (
      <div>
        <br />
        <PincodesComponent
          data={this.state.pincodes}
          selectedPin={this.state.selectedPin}
          add={this.onAdd}
          nameChange={this.onNameChange}
          onNameUpdate={this.onNameUpdate}
          onDateUpdate={this.onDateUpdate}
          initiatedEditing={this.initiatedEditing}
          update={this.editPin}
          onPinTriggerDelete={this.onPinTriggerDelete}
          deletePin={this.deletePin}
          onChangePinStatus={this.onChangePinStatus}
        />
      </div>
    );
  }
  getPincodes = () => {
    var apiPath = new Uri(clientHost + PINCODE_LIST)
      // .addQueryParam("storeid", storeFresh.storeId)
      .addQueryParam("offset", 0)
      .addQueryParam("limit", 10);
    getResponseClient(
      apiPath,
      storeFresh.key,
      (onSuccessResponse) => {
        this.setState({ pincodes: onSuccessResponse.result });
      },
      (onError) => {
        console.log(onError.message);
      }
    );
  };
  async componentDidMount() {
    this.getPincodes();
  }
}
export default PincodesContainer;
